import axios from 'axios'
import { processGeojson, processRowObject } from 'kepler.gl/processors'
import { addDataToMap, layerConfigChange, layerVisConfigChange, layerVisualChannelConfigChange, addFilter, setFilter, interactionConfigChange, removeDataset, layerTextLabelChange } from 'kepler.gl/actions'
import { beepLayerBy } from './layerActions'
import { setIsLoading } from './dataActions'
import { transformWardPointsForBeepingEffect, getWardPointsData } from '../../utils/wardUtils'
import { getCentersOfGeoJsonFeatures } from '../../utils/utils'
import { WARD_AREAS, WARD_POINTS } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Constants
let apiCancelTokenSource = null

// Dispatch Ward Areas To Map
export function loadWardAreas(wardAreas) {
  return (dispatch, getState) => {
    // Remove Previous Dataset
    dispatch( removeDataset(WARD_AREAS.DATA_ID) )
    dispatch( removeDataset(WARD_AREAS.CENTER_DATA_ID) )

    if(wardAreas) {
      // Build `ward-areas` dataset
      const datasetInfo = { id: WARD_AREAS.DATA_ID, label: WARD_AREAS.DATA_LABEL }
      const data = processGeojson(wardAreas)
      const dataset = { info: datasetInfo, data }

      // Get Ward Area Centers GeoJson & Build `ward-area-centers` dataset
      const centers = getCentersOfGeoJsonFeatures(wardAreas)
      const centerDatasetInfo = { id: WARD_AREAS.CENTER_DATA_ID, label: WARD_AREAS.CENTER_DATA_LABEL }
      const centerData = processRowObject(centers)
      const centerDataset = { info: centerDatasetInfo, data: centerData }

      // Options & Configs
      const options = { readOnly: true, centerMap: true }

      // Add Data to Map
      dispatch( addDataToMap({ datasets: [ dataset, centerDataset ], options }) )

      // Apply Configs
      const { map } = getState().keplerGl
      if(map) {
        // Layer Configs
        const { layers } = map.visState
        layers.forEach(l => {
          if(l.type === 'geojson' && l.config.dataId === datasetInfo.id) {
            // Apply Styles
            dispatch( layerVisConfigChange(l, {
              opacity: 0.05,
              strokeOpacity: 0.5,
              strokeColor: WARD_AREAS.STROKE_COLOR,
              thickness: 0.5
            }) )

            // Color Range
            const colorRange = {
              category: 'Barikoi',
              colors: WARD_AREAS.COLOR_PALETTE,
              name: 'Wards',
              type: 'quantile'
            }

            dispatch( layerVisConfigChange(l, { colorRange }) )

            // Set Color Field by to distinguish Ward Areas
            const _wardAreasDataset = map.visState.datasets[ l.config.dataId ]
            const typeField = _wardAreasDataset.fields.find(el => el.name === WARD_AREAS.COLOR_KEY)

            // Dispatch Color Field By
            dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )

            // Add Filter
            dispatch( addFilter(l.config.dataId) )

            // Filter Out All
            dispatch( filterWardAreas([]) )

            // Tooltip Configs
            const fieldsToShow = WARD_AREAS.TOOLTIP_FIELDS
            const { tooltip } = map.visState.interactionConfig
            tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
            dispatch( interactionConfigChange(tooltip) )
          }

          if(l.type === 'point' && l.config.dataId === centerDatasetInfo.id) {
            dispatch( layerConfigChange(l, { color: [ 255, 255, 255, 0 ] }) )
            dispatch( layerVisConfigChange(l, { radius: 0 }) )

            // Set Outlets Text Label to `label`
            const centersDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
            const labelField = centersDataset?.fields.find(el => el.name === 'label')
            if(labelField) {
              dispatch( layerTextLabelChange(l, 0, 'field', labelField) )
              dispatch( layerTextLabelChange(l, 0, 'size', 22) )
              dispatch( layerTextLabelChange(l, 0, 'anchor', 'middle') )
              dispatch( layerTextLabelChange(l, 0, 'alignment', 'center') )

              // Set Text Label Color
              l.config.textLabel.forEach((label, li) => {
                dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
              })
            }

            // Add Filter
            dispatch( addFilter(l.config.dataId) )
            
            // Filter Out All
            dispatch( filterWardAreas([]) )
          }
        })
      }
    }
  }
}

// Filter Ward Areas
export function filterWardAreas(selectedWards) {
  return (dispatch, getState) => {
    let wardNumbers = []

    // If selectedWards is empty
    if(!selectedWards || selectedWards.length === 0) {
      wardNumbers = [ '' ]

    } else if(selectedWards.includes('All')) {
        // If `All` is selected
        wardNumbers = []

    } else {
      // If Valid Wards Selected
      wardNumbers = selectedWards
    }

    // Set Filter
    const { map } = getState().keplerGl
    if(map) {
      // Get Filter Index
      const { filters } = map.visState

      const filterIndex = filters.findIndex(f => f.dataId[0] === WARD_AREAS.DATA_ID)
      const centerFilterIndex = filters.findIndex(f => f.dataId[0] === WARD_AREAS.CENTER_DATA_ID)

      if(filterIndex >= 0) {
        // Filter Ward Areas
        dispatch( setFilter(filterIndex, 'name', WARD_AREAS.FILTER_KEY) )
        dispatch( setFilter(filterIndex, 'value', wardNumbers) )

        // Filter Ward Centers
        dispatch( setFilter(centerFilterIndex, 'name', WARD_AREAS.CENTER_FILTER_KEY) )
        dispatch( setFilter(centerFilterIndex, 'value', wardNumbers) )
      }
    }
  }
}

// Dispatch Ward Points To Map
export function loadWardPointsToMap(wardPoints) {
  return (dispatch, getState) => {
    // Remove Previous Dataset
    dispatch( removeDataset(WARD_POINTS.DATA_ID) )

    if(wardPoints) {
      // Transform For Beeping Effect
      const _wardPoints = transformWardPointsForBeepingEffect(wardPoints, 'placeType', 'Commercial')

      // Build `ward-points` dataset
      const datasetInfo = { id: WARD_POINTS.DATA_ID, label: WARD_POINTS.DATA_LABEL }
      const data = processRowObject(_wardPoints)
      const dataset = { info: datasetInfo, data }

      // Options & Configs
      const options = { readOnly: true, centerMap: true }

      // Add Data to Map
      dispatch( addDataToMap({ datasets: [ dataset ], options }) )

      // Apply Layer Configs
      const { map } = getState().keplerGl
      if(map) {
        const { layers } = map.visState
        layers.forEach(l => {
          if(l.type === 'point' && l.config.dataId === datasetInfo.id) {
            // Color Range
            const colorRange = {
              category: 'Barikoi',
              colors: WARD_POINTS.COLOR_PALETTE,
              name: 'Holdings',
              type: 'quantile'
            }

            dispatch( layerVisConfigChange(l, { colorRange }) )

            // Set Color Field by to distinguish Ward Points
            const _wardPointsDataset = map.visState.datasets[ l.config.dataId ]
            const typeField = _wardPointsDataset.fields.find(el => el.name === WARD_POINTS.COLOR_KEY)

            // Dispatch Color Field By
            dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )

            // Add Filter
            dispatch( addFilter(l.config.dataId) )

            // Tooltip Configs
            const fieldsToShow = WARD_POINTS.TOOLTIP_FIELDS
            const { tooltip } = map.visState.interactionConfig
            tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
            dispatch( interactionConfigChange(tooltip) )

            // Add Beeping Feature
            dispatch( beepLayerBy(l, 'beep') )
          }
        })
      }
    }
  }
}

// Filter Ward Points
export function filterWardPoints(selectedPlaceTypes) {
  return (dispatch, getState) => {
    let placeTypes = []

    // If selectedPlaceTypes is empty
    if(!selectedPlaceTypes || selectedPlaceTypes.length === 0) {
      placeTypes = [ '' ]

    } else if(selectedPlaceTypes.includes('All')) {
      // If `All` is selected
      placeTypes = []

    } else {
      // If Valid Place Types Selected
      placeTypes = selectedPlaceTypes
    }

    // Set Filter
    const { map } = getState().keplerGl
    if(map) {
      // Get Filter Index
      const { filters } = map.visState

      const filterIndex = filters.findIndex(f => f.dataId[0] === WARD_POINTS.DATA_ID)

      if(filterIndex >= 0) {
        dispatch( setFilter(filterIndex, 'name', WARD_POINTS.FILTER_KEY) )
        dispatch( setFilter(filterIndex, 'value', placeTypes) )
      }
    }
  }
}

// Set Ward Points Layer Visibility
export function setWardPointsLayerVisibility(isVisible) {
  return (dispatch, getState) => {
    const { map } = getState().keplerGl
    if(map) {
      const { layers } = map.visState
      layers.forEach(l => {
        if(l.type === 'geojson' && l.config.dataId === WARD_POINTS.DATA_ID) {
          dispatch( layerConfigChange(l, { isVisible }) )
        }
      })
    }
  }
}

// Set Ward Dropdown Options
export function setWardDropdownOptions(wardDropdownOptions) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_WARD_DROPDOWN_OPTIONS, payload: wardDropdownOptions })
  }
}

// Set Selected Wards
export function setSelectedWards(selectedWards) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_SELECTED_WARDS, payload: selectedWards })
  }
}

// Set Ward Points
export function setWardPoints(wardPoints) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_WARD_POINTS, payload: wardPoints })
  }
}

// Load Selected Ward Points
export function loadSelectedWardPoints(selectedWards) {
  return (dispatch, getState) => {
    // Do Nothing if multiple selected
    if(selectedWards && selectedWards.length > 1) {
      return
    }

    // Load `wardPoints` data
    if(selectedWards.length === 0 || (selectedWards.length > 1 && selectedWards.includes('All'))) {
      dispatch( setWardPoints(null) )

    } else {
        // Set isLoading
        dispatch( setWardPoints(null) )
        dispatch( setIsLoading(true) )

        // Get Ward Numbers
        const { wardDropdownOptions } = getState().ward
        let selectedWardId = null
        wardDropdownOptions.forEach(w => {
          if(w.label !== 'All' && w.options) {
            w.options.forEach(o => {
              if(selectedWards.includes(o.value)) {
                selectedWardId = o.id
              }
            })
          }
        })

        if(selectedWardId) {
          // Cancel Pending Requests
          if(apiCancelTokenSource) {
            apiCancelTokenSource.cancel()
          }

          // Save API Cancel Token
          apiCancelTokenSource = axios.CancelToken.source('Pending request cancelled.')

          getWardPointsData(
            selectedWardId,
            { cancelToken: apiCancelTokenSource.token }
          )
            .then(wardPoints => {
              dispatch( setWardPoints(wardPoints) )
              dispatch( setIsLoading(false) )
            })
            .catch(err => {
              console.error(err)

              if(!axios.isCancel(err)) {
                // Set isLoading
                dispatch( setWardPoints(null) )
                dispatch( setIsLoading(false) )
              }
            })
        }
    }
  }
}