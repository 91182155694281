import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _debounce from 'lodash.debounce'
import _isEqual from 'lodash.isequal'

// Import Components
import KeplerGL from './KeplerGL'
import SearchMap from './SearchMap'

// Import Actions & Methods
import { updateMap } from 'kepler.gl/actions'
import { loadInitialCustomMap } from '../../store/actions/mapActions'
import { setLayerSizeWithZoomLevel } from '../../store/actions/layerActions'
import { loadZoneAreas, filterZoneAreas } from '../../store/actions/zoneActions'
import { loadWardAreas, filterWardAreas, loadWardPointsToMap, filterWardPoints } from '../../store/actions/wardActions'
import { renderBarikoiAttributions, renderCustomActionPanelsIntoDom } from '../../utils/utils'

class MapGL extends React.PureComponent {
  state = {
    mapWidth: 240,
    mapHeight: 240
  }

  componentDidMount() {
    const { dispatch, zoom } = this.props

    // Handle Map Resize
    this._handleMapResize()

    // Dispatch Initial Custom Map Without Data
    dispatch( loadInitialCustomMap() )

    // Set Layer Size with Zoom Level
    dispatch( setLayerSizeWithZoomLevel(zoom) )

    // Render Custom Action Panels Into Map Control DOM
    renderCustomActionPanelsIntoDom()

    // Render Barikoi Attribution
    renderBarikoiAttributions()
  }

  componentDidUpdate(prevProps) {
    const { dispatch, zoom, zoneAreas, wardAreas, wardPoints, selectedZones, selectedWards, selectedPlaceTypes } = this.props

    // If `zoom` changes in props
    if(prevProps.zoom !== zoom) {
      if(zoom <= 22) {
        dispatch( setLayerSizeWithZoomLevel(zoom) )

      } else {
        dispatch( updateMap({ zoom: 22 }) )
      }
    }

    // If `zoneAreas` changes in props
    if(prevProps.zoneAreas !== zoneAreas) {
      // Dispatch new `zoneAreas` to Map
      dispatch( loadZoneAreas(zoneAreas) )
    }

    // If `wardAreas` changes in props
    if(prevProps.wardAreas !== wardAreas) {
      // Dispatch new `wardAreas` to Map
      dispatch( loadWardAreas(wardAreas) )
    }

    // If `wardPoints` changes in props
    if(prevProps.wardPoints !== wardPoints) {
      // Dispatch new `wardPoints` to Map
      dispatch( loadWardPointsToMap(wardPoints) )

      // Filter Ward Points based on selectedPlaceTypes
      dispatch( filterWardPoints(selectedPlaceTypes) )
    }

    // If `selectedZones` changes in props
    if(!_isEqual(prevProps.selectedZones, selectedZones)) {
      // Filter `zoneAreas`
      dispatch( filterZoneAreas(selectedZones) )
    }

    // If `selectedWards` changes in props
    if(!_isEqual(prevProps.selectedWards, selectedWards)) {
      // Filter `wardAreas`
      dispatch( filterWardAreas(selectedWards) )
    }

    // If `selectedPlaceTypes` changes in props
    if(!_isEqual(prevProps.selectedPlaceTypes, selectedPlaceTypes)) {
      // Filter `wardPoints`
      dispatch( filterWardPoints(selectedPlaceTypes) )
    }
}

  // Handle Map Resize
  _handleMapResize = () => {
    // Add Event Listeners
    const mapContainerElement = document.getElementById('map-container')
    if(mapContainerElement) {
      new ResizeObserver(_debounce(() => {
        this.setState({
          mapWidth: mapContainerElement.clientWidth,
          mapHeight: mapContainerElement.clientHeight
        })
      }, 10)).observe(mapContainerElement)
    }
  }

  render() {
    const { wardPoints } = this.props
    const { mapWidth, mapHeight } = this.state

    return (
      <div id='map-container' style={ containerStyles }>
        <KeplerGL
          id='map'
          mapboxApiAccessToken={ '' }
          width={ mapWidth }
          height={ mapHeight }
          mint={ true }
        />

        { wardPoints &&
          <div style={{ position: 'absolute', top: '0.5rem', left: '0.5rem' }}>
            <SearchMap />
          </div>
        }
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  width: '100%',
  height: '100%',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  position: 'relative'
}

// Prop Types
MapGL.propTypes = {
  zoneAreas: PropTypes.object,
  wardAreas: PropTypes.object,
  wardPoints: PropTypes.object,
  selectedZones: PropTypes.array,
  selectedWards: PropTypes.array,
  selectedPlaceTypes: PropTypes.array
}

MapGL.defaultProps = {
  zoneAreas: null,
  wardAreas: null,
  wardPoints: null,
  selectedZones: [],
  selectedWards: [],
  selectedPlaceTypes: []
}

const mapStateToProps = state => ({
  zoom: state.keplerGl.map ?
    state.keplerGl.map.mapState.zoom
    :
    0
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(MapGL)