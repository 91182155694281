import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class ErrorBoundary extends React.PureComponent {
  state = {
    error: null,
    errorInfo: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    const { children, locale } = this.props
    const { error } = this.state

    return (
      <React.Fragment>
        {
          error ?
            <div style={ containerStyles }>
              <h4 style={{ whiteSpace: 'normal' }}>
                { locale === 'bn' ?
                  'দুঃখিত! কোনো সমস্যা হয়েছে।'
                  :
                  'Oops! Something went wrong.'
                }
              </h4>
            </div>
            :
            children
        }
      </React.Fragment>
    )
  }
}

// JSS Styles
const containerStyles = {
  padding: '16px',
  color: '#909090',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}

// Prop Types
ErrorBoundary.propTypes = {
  locale: PropTypes.string
}

ErrorBoundary.defaultProps = {
  locale: 'en'
}

const mapStateToProps = state => ({
  locale: state.locale.locale
})

export default connect(mapStateToProps, null)(ErrorBoundary)