import axios from 'axios'
import papa from 'papaparse'
import * as ActionTypes from './actionTypes'
import { API, ZONE_AREAS, WARD_AREAS } from '../../App.config'
import { loadZoneAreas } from './zoneActions'
import { loadWardAreas } from './wardActions'

// Set isLoading for data
export function setIsLoading(isLoading) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_IS_LOADING, payload: isLoading })
  }
}


// Upload CSV Zone & Ward Data To Map
export function uploadZoneWardData(file) {
  return dispatch => {
    // Read CSV File using PapaParse
    papa.parse(file, {
      download: typeof file === 'string' ? true : false,
      header: true,
      skipEmptyLines: 'greedy',
      complete: results => {
        // Terminating Conditions
        if(!results?.data?.length) {
          return
        }

        if(!results.data[0]?.ward_number && !results.data[0]?.zone_number) {
          return
        }

        if(results.data[0]?.ward_number) {
          dispatch( updateWardsMetaDataToMap(results.data) )

          // Upload File To Server
          if(typeof file !== 'string') {
            uploadFileDataToServer({ type: 'ward-data', file })
          }

        } else if(results.data[0]?.zone_number) {
          dispatch( updateZonesMetaDataToMap(results.data) )

          // Upload File To Server
          if(typeof file !== 'string') {
            uploadFileDataToServer({ type: 'zone-data', file })
          }
        }
      }
    })
  }
}

// Update Zones Metadata to Map
function updateZonesMetaDataToMap(zonesData) {
  return (dispatch, getState) => {
    if(!zonesData?.length) {
      return
    }

    // Zones Dataset
    const datasets = getState()?.keplerGl?.map?.visState?.datasets ?? {}
    const zoneFeatures = datasets[ ZONE_AREAS.DATA_ID ]?.dataContainer?._rows?.map(r => r[0]) ?? []

    if(!zoneFeatures?.length) {
      return
    }

    // Append Metadata to Zones
    const skipKeys = [ 'id', 'zone_number', 'city_corp', 'boundary_type' ]
    const featureCollection = {
      type: 'FeatureCollection',
      features: zoneFeatures.map(z => {
        const metaData = zonesData.find(zd => z.properties.zone_number.endsWith(`Zone-${ zd.zone_number }`))

        if(metaData) {
          // Exclude Critical Keys
          Object.keys(metaData).forEach(k => {
            if(!skipKeys.includes(k)) {
              z.properties[k] = metaData[k]
            }

            if(k === 'holdings_paid_percentage') {
              z.properties.target_achieved = `${ metaData[k] }%`
              z.properties.target_achieved_status = metaData[k] > 79 ? '80% - 100%' : metaData[k] > 39 ? '40% - 79%' : '0% - 39%'
            }
          })
        }

        return z
      })
    }

    // Load Zones Data To Map
    dispatch( loadZoneAreas(featureCollection) )
  }
}


// Update Wards Metadata to Map
function updateWardsMetaDataToMap(wardsData) {
  return (dispatch, getState) => {
    if(!wardsData?.length) {
      return
    }

    // Wards Dataset
    const datasets = getState()?.keplerGl?.map?.visState?.datasets ?? {}
    const wardFeatures = datasets[ WARD_AREAS.DATA_ID ]?.dataContainer?._rows?.map(r => r[0]) ?? []

    if(!wardFeatures?.length) {
      return
    }

    // Append Metadata to Wards
    const skipKeys = [ 'id', 'ward_number', 'zone_id', 'zone_number', 'boundary_type' ]
    const featureCollection = {
      type: 'FeatureCollection',
      features: wardFeatures.map(w => {
        const metaData = wardsData.find(wd => w.properties.ward_number.startsWith(`Ward-${ wd.ward_number } `))

        if(metaData) {
          // Exclude Critical Keys
          Object.keys(metaData).forEach(k => {
            if(!skipKeys.includes(k)) {
              w.properties[k] = metaData[k]
            }

            if(k === 'holdings_paid_percentage') {
              w.properties.target_achieved = `${ metaData[k] }%`
              w.properties.target_achieved_status = metaData[k] > 79 ? '80% - 100%' : metaData[k] > 39 ? '40% - 79%' : '0% - 39%'
            }
          })
        }

        return w
      })
    }

    // Load Wards Data To Map
    dispatch( loadWardAreas(featureCollection) )
  }
}

// Upload File Data To Server
export function uploadFileDataToServer({ file, type }) {
  try {
    const formData = new FormData()
    formData.append('type', type)
    formData.append('file', file)
    axios.post(API.UPLOAD_FILE_DATA, formData)
    
  } catch(err) {
    // Pass
  }
}

// Fetch Latest Uploaded Files
export function fetchLatestUploadedFiles() {
  return axios.get(API.FETCH_LATEST_FILES)
    .then(res => {
      if(!res?.data?.data?.length) {
        throw new Error()
      }

      return res.data.data.map(d => d?.file_path ?? '')
    })
    .catch(err => {
      // Pass
    })
}