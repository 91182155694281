import React from 'react'

class LinearProgressBar extends React.PureComponent {
  render() {
    return (
      <div className='linear-progress'>
        <div className='linear-progress-indeterminate' />
      </div>
    )
  }
}

export default LinearProgressBar