import * as ActionTypes from '../actions/actionTypes'

const initialState = {
  locale: 'en'
}

const localeReducer = (state=initialState, action) => {
  switch(action.type) {
    case ActionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.payload === 'bn' || action.payload === 'en' ?
          action.payload
          :
          'en'
      }

    default:
      return state
  }
}

export default localeReducer