import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Typography, Select } from 'antd'

class ZoneSelectMenu extends React.PureComponent {
    render() {
        const { zoneDropdownOptions, selectedZones, onChange, disabled, locale } = this.props

        return (
            <div style={ containerStyles }>
                <Typography.Title
                    level={ 5 }
                    type='secondary'
                >
                    { locale === 'bn' ?
                        'জোন নির্বাচন করুন'
                        :
                        'Select Zone(s)'
                    }
                </Typography.Title>

                <Select
                    mode='multiple'
                    allowClear={ true }
                    options={ zoneDropdownOptions }
                    optionLabelProp='label'
                    optionFilterProp='label'
                    placeholder={
                        locale === 'bn' ?
                        'জোন নির্বাচন করুন'
                        :
                        'Select Zone(s)...'
                    }
                    showArrow={ true }
                    loading={ false }
                    value={ selectedZones }
                    onChange={ onChange }
                    disabled={ disabled }
                    style={{ width: '100%' }}
                />
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

// Prop Types
ZoneSelectMenu.propTypes = {
    zoneDropdownOptions: PropTypes.array,
    selectedZones: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    locale: PropTypes.string
}

ZoneSelectMenu.defaultProps = {
    zoneDropdownOptions: [],
    selectedZones: [],
    onChange: () => null,
    disabled: false,
    locale: 'en'
}

const mapStateToProps = state => ({
    locale: state.locale.locale
})

export default connect(mapStateToProps, null)(ZoneSelectMenu)