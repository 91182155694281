import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { MenuOutlined } from '@ant-design/icons'

class MenuIcon extends React.PureComponent {
    state = {
        isHovered: false
    }

    // Toggle Is Hovered
    _toggleIsHovered = () => {
        const { isHovered } = this.state
        this.setState({ isHovered: !isHovered })
    }

    render() {
        const { onClick, style } = this.props
        const { isHovered } = this.state

        return (
            <div
                onMouseEnter={ this._toggleIsHovered }
                onMouseLeave={ this._toggleIsHovered }
                onClick={ onClick }
                style={{ ...iconContainerStyles, ...style }}
            >
                <MenuOutlined
                    style={{
                        fontSize: '20px',
                        color: isHovered ? '#f5f5f5' : '#88909d'
                    }}
                />
            </div>
        )
    }
}

// JSS Styles
const iconContainerStyles = {
    position: 'absolute',
    top: '8px',
    left: '8px',
    padding: '6px',
    background: '#29323c',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
}

// Prop Types
MenuIcon.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object
}

MenuIcon.defaultProps = {
    onClick: () => null,
    style: {}
}

export default MenuIcon