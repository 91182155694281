import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AUTH_URL, WARD_POINTS } from '../../App.config'

// Import Components
import { Table, Divider, Button, Row, Col, Card, Statistic, Image } from 'antd'

// Import Assets
import fallbackImage from '../../assets/fallback.png'

class HoldingDetails extends React.PureComponent {
  // Get Clicked Object Data
  _getClickedObjectData = (wardPointsDataset, clicked) => {
    if(!wardPointsDataset?.dataContainer?._rows?.length || wardPointsDataset?.dataContainer?._rows?.length <= clicked?.index) {
      return {}
    }

    const fields = wardPointsDataset.fields
    const clickedRow = wardPointsDataset.dataContainer._rows[ clicked?.index ]
    const clickedObject = {}
    fields.forEach(f => {
      clickedObject[ f.name ] = clickedRow[ f.fieldIdx ]
    })

    return clickedObject
  }

  render() {
    const { locale, wardPointsDataset, clicked } = this.props

    const data = clicked && clicked.object && clicked.object.properties ?
      clicked.object.properties
      :
      this._getClickedObjectData(wardPointsDataset, clicked)

    return (
      <div style={ containerStyles }>
        <div style={{ width: '100%' }}>
          <Row
            justify='space-between'
            style={{
              width: '100%'
            }}
          >
            <Col xs={ 24 }>
              <Image
                src={ `${ AUTH_URL }${ data?.image_url ?? '' }` }
                fallback={ fallbackImage }
                alt='Holding Cover'
                width='100%'
              />
            </Col>
          </Row>
        </div>

        <div style={{ width: '100%', padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Row
            gutter={[ 16, 16 ]}
            justify='space-between'
            style={{
              width: '100%'
            }}
          >
            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Holdings Revenue'
                  value={ data.holdings_revenue }
                  suffix={
                    <span>{ 'Cr' }</span>
                  }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='TL Revenue'
                  value={ data.tl_revenue }
                  suffix={
                    <span>{ 'Cr' }</span>
                  }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Total Holdings'
                  value={ data.total_holdings }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Total TL'
                  value={ data.total_tl }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Unpaid Holdings'
                  value={ data.unpaid_holdings }
                  valueStyle={{
                    color: '#c70039',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Unpaid TL'
                  value={ data.unpaid_tl }
                  valueStyle={{
                    color: '#c70039',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>

        <div style={{ padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Table
            title={() => (
              <h5
                style={{
                  margin: 0,
                  width: '100%',
                  textAlign: 'center',
                  color: '#808080'
                }}
              >
                { locale === 'bn' ?
                  'মাস্টার ডাটা'
                  :
                  'Master Data'
                }
              </h5>
            )}
            dataSource={ dataSource }
            columns={ columns }
            pagination={ false }
            scroll={{
              y: 200
            }}
            size='small'
            onHeaderRow={() => ({ style: { fontSize: '11px' } })}
            onRow={() => ({ style: { fontSize: '11px' } })}
          />
        </div>

        <div style={{ padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Table
            title={() => (
              <h5
                style={{
                  margin: 0,
                  width: '100%',
                  textAlign: 'center',
                  color: '#808080'
                }}
              >
                { locale === 'bn' ?
                  'প্রপার্টি ডিটেলস'
                  :
                  'Property Details'
                }
              </h5>
            )}
            dataSource={ dataSource2 }
            columns={ columns2 }
            pagination={ false }
            scroll={{
              y: 200
            }}
            size='small'
            onHeaderRow={() => ({ style: { fontSize: '11px' } })}
            onRow={() => ({ style: { fontSize: '11px' } })}
          />
        </div>

        <div style={{ padding: '16px', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Table
            title={() => (
              <h5
                style={{
                  margin: 0,
                  width: '100%',
                  textAlign: 'center',
                  color: '#808080'
                }}
              >
                { locale === 'bn' ?
                  '১০ বছরের ডাটা'
                  :
                  '10 Year Data'
                }
              </h5>
            )}
            dataSource={ dataSource3 }
            columns={ columns3 }
            pagination={ false }
            scroll={{
              y: 200
            }}
            size='small'
            onHeaderRow={() => ({ style: { fontSize: '11px' } })}
            onRow={() => ({ style: { fontSize: '11px' } })}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Divider
            plain={ true }
            style={{
              margin: 0,
              marginBottom: '8px',
              width: '100%',
              color: '#a0a0a0'
            }}
          >
            { 'Export Data' }
          </Divider>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              gap: '8px'
            }}
          >
            <Button
              size='small'
              type='dashed'
            >
              { 'Excel' }
            </Button>

            <Button
              size='small'
              type='primary'
            >
              { 'PDF' }
            </Button>
          </div>
      </div>
      </div>
    )
  }
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: 'Area',
    dataIndex: 'area',
    key: 'area'
  },
  {
    title: 'Const. Date',
    dataIndex: 'construction_date',
    key: 'construction_date'
  },
  {
    title: 'Holding Tax',
    dataIndex: 'holding_tax',
    key: 'holding_tax'
  },
  {
    title: 'Cleaning Tax',
    dataIndex: 'cleaning_tax',
    key: 'cleaning_tax'
  }
]

const dataSource = [
  {
    key: '1',
    name: 'Super Shop',
    address: '2/A, Banani',
    area: 'Banani',
    construction_date: 'Jan 01, 1994',
    holding_tax: 50000,
    cleaning_tax: 60000
  },
  {
    key: '2',
    name: 'Cielo',
    address: '3/A, Banani',
    area: 'Banani',
    construction_date: 'Jan 15, 1994',
    holding_tax: 30000,
    cleaning_tax: 40000
  },
  {
    key: '3',
    name: 'BFC',
    address: '4/A, Banani',
    area: 'Banani',
    construction_date: 'Jan 01, 1992',
    holding_tax: 10000,
    cleaning_tax: 20000
  }
]


//
const columns2 = [
  {
    title: 'TL No',
    dataIndex: 'trade_license_number',
    key: 'trade_license_number'
  },
  {
    title: 'E-Holding',
    dataIndex: 'e_holding',
    key: 'e_holding'
  },
  {
    title: 'Opening Date',
    dataIndex: 'opening_date',
    key: 'opening_date'
  },
  {
    title: 'TL Tax',
    dataIndex: 'trade_license_tax',
    key: 'trade_license_tax'
  },
  {
    title: 'Signboard Tax',
    dataIndex: 'signboard_tax',
    key: 'signboard_tax'
  },
  {
    title: 'Last Payment Date',
    dataIndex: 'last_payment_date',
    key: 'last_payment_date'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  }
]

const dataSource2 = [
  {
    key: '1',
    trade_license_number: '334343423',
    e_holding: '234239034',
    opening_date: 'Jan 01, 2004',
    trade_license_tax: 331000,
    signboard_tax: 50000,
    last_payment_date: 'Feb 22, 2020',
    status: 'Due'
  },
  {
    key: '2',
    trade_license_number: '33434344343',
    e_holding: '2342323234',
    opening_date: 'Aug 01, 2014',
    trade_license_tax: 3232000,
    signboard_tax: 20000,
    last_payment_date: 'Feb 20, 2018',
    status: 'Due'
  },
  {
    key: '3',
    trade_license_number: '23423432344',
    e_holding: '37654456',
    opening_date: 'Aug 18, 2011',
    trade_license_tax: 0,
    signboard_tax: 0,
    last_payment_date: 'Feb 02, 2015',
    status: 'Paid'
  }
]

//
const columns3 = [
  {
    title: 'TL No',
    dataIndex: 'trade_license_number',
    key: 'trade_license_number'
  },
  {
    title: 'E-Holding',
    dataIndex: 'e_holding',
    key: 'e_holding'
  },
  {
    title: 'Opening Date',
    dataIndex: 'opening_date',
    key: 'opening_date'
  },
  {
    title: 'TL Tax',
    dataIndex: 'trade_license_tax',
    key: 'trade_license_tax'
  },
  {
    title: 'Signboard Tax',
    dataIndex: 'signboard_tax',
    key: 'signboard_tax'
  },
  {
    title: 'Last Payment Date',
    dataIndex: 'last_payment_date',
    key: 'last_payment_date'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  }
]

const dataSource3 = [
  {
    key: '1',
    trade_license_number: '334343423',
    e_holding: '234239034',
    opening_date: 'Jan 01, 2004',
    trade_license_tax: 331000,
    signboard_tax: 50000,
    last_payment_date: 'Feb 22, 2020',
    status: 'Due'
  },
  {
    key: '2',
    trade_license_number: '33434344343',
    e_holding: '2342323234',
    opening_date: 'Aug 01, 2014',
    trade_license_tax: 3232000,
    signboard_tax: 20000,
    last_payment_date: 'Feb 20, 2018',
    status: 'Due'
  },
  {
    key: '3',
    trade_license_number: '23423432344',
    e_holding: '37654456',
    opening_date: 'Aug 18, 2011',
    trade_license_tax: 0,
    signboard_tax: 0,
    last_payment_date: 'Feb 02, 2015',
    status: 'Paid'
  }
]

// JSS Styles
const containerStyles = {
  padding: '16px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
  overflow: 'auto'
}

const cardHoveredStyles = {
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
}

// Prop Types
HoldingDetails.propTypes = {
  eHoldings: PropTypes.array,
  locale: PropTypes.string,
  clicked: PropTypes.object
}

HoldingDetails.defaultProps = {
  eHoldings: [],
  locale: 'en',
  clicked: null
}

const mapStateToProps = state => ({
  locale: state.locale.locale,
  wardPointsDataset: state?.keplerGl?.map?.visState?.datasets[ WARD_POINTS.DATA_ID ] ?? {}
})

export default connect(mapStateToProps)(HoldingDetails)