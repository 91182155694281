export const BASE_URL = window?._env_?.REACT_APP_BASE_URL
export const AUTH_URL = window?._env_?.REACT_APP_AUTH_URL

// AUTH
export const AUTH = {
    LOGIN_API: `${AUTH_URL}/auth/login`,
    GET_USER_API: `${AUTH_URL}/auth/user`
}

// API
export const API = {
    UPLOAD_FILE_DATA: `${BASE_URL}/store-file`,
    FETCH_LATEST_FILES: `${BASE_URL}/get-file`

}

// Map Configs
export const MAP = {
    MAP_STYLES: [
        { value: 'barikoi-light', label: 'Light', labelBn: 'লাইট' },
        { value: 'barikoi-dark', label: 'Dark', labelBn: 'ডার্ক' }
    ]
}

// Zone Configs
export const ZONE_AREAS = {
    // GET_ZONE_AREAS_API: 'https://api.bmapsbd.com/api/zone/geoJSON',
    GET_ZONE_AREAS_API: `${AUTH_URL}/zone-geoJSON`,
    DATA_ID: 'zone-areas',
    DATA_LABEL: 'Zones',
    CENTER_DATA_ID: 'zone-area-centers',
    CENTER_DATA_LABEL: 'Zone Centers',
    STROKE_COLOR: [120, 120, 120],
    TOOLTIP_FIELDS: [
        {
            name: 'zone_number',
            format: null
        },
        {
            name: 'city_corp',
            format: null
        },
        {
            name: 'target_achieved',
            format: null
        },
        {
            name: 'holdings_due',
            format: null
        },
        {
            name: 'holdings_paid',
            format: null
        },
        {
            name: 'total_holdings',
            format: null
        },
        {
            name: 'holdings_paid_percentage',
            format: null
        }
    ],
    FILTER_KEY: 'zone_number',
    CENTER_FILTER_KEY: 'zone_number',
    COLOR_KEY: 'target_achieved_status',
    COLOR_PALETTE: [
        '#ff0000',
        '#ffff00',
        '#00ff00'
    ]
}

// Ward Area Configs
export const WARD_AREAS = {
    // GET_WARD_AREAS_API: 'https://api.bmapsbd.com/api/ward/geoJSON',
    GET_WARD_AREAS_API: `${BASE_URL}/wards`,
    DATA_ID: 'ward-areas',
    DATA_LABEL: 'Wards',
    CENTER_DATA_ID: 'ward-area-centers',
    CENTER_DATA_LABEL: 'Ward Centers',
    TOOLTIP_FIELDS: [
        {
            name: 'ward_number',
            format: null
        },
        {
            name: 'zone_number',
            format: null
        },
        {
            name: 'target_achieved',
            format: null
        },
        {
            name: 'target_achieved_status',
            format: null
        },
        {
            name: 'holdings_due',
            format: null
        },
        {
            name: 'holdings_paid',
            format: null
        },
        {
            name: 'total_holdings',
            format: null
        },
        {
            name: 'holdings_paid_percentage',
            format: null
        }
    ],
    FILTER_KEY: 'ward_number',
    CENTER_FILTER_KEY: 'ward_number',
    COLOR_KEY: 'target_achieved_status',
    STROKE_COLOR: [34, 139, 34],
    // COLOR_PALETTE: [
    //     '#c78bb9',
    //     '#5aca5d',
    //     '#bf5cd3',
    //     '#8dbd3a',
    //     '#905fd8',
    //     '#4ba033',
    //     '#d84cb2',
    //     '#45c27f',
    //     '#ce307b',
    //     '#b6b731',
    //     '#5c75e3',
    //     '#ddaf37',
    //     '#5b4ba8',
    //     '#e1892a',
    //     '#4f77bb',
    //     '#d94d2b',
    //     '#45c7c8',
    //     '#d7314d',
    //     '#6ebf92',
    //     '#e63e77',
    //     '#438c4f',
    //     '#9a4ca4',
    //     '#5f8529',
    //     '#e75e9d',
    //     '#31947c',
    //     '#ab367d',
    //     '#9bb76d',
    //     '#bd94e4',
    //     '#a88a27',
    //     '#7e67ae',
    //     '#bcb35a',
    //     '#df80c3',
    //     '#636b16',
    //     '#64a7dd',
    //     '#ab3e26',
    //     '#2d6a41',
    //     '#de6262',
    //     '#5f6e34',
    //     '#e97e99',
    //     '#978c4d',
    //     '#975181',
    //     '#daa36a',
    //     '#a5354a',
    //     '#866527',
    //     '#bc5371',
    //     '#ae6529',
    //     '#91495a',
    //     '#ea835e',
    //     '#a25c42',
    //     '#e08d8b'
    // ]
    COLOR_PALETTE: [
        '#ff0000',
        '#ffff00',
        '#00ff00'
    ]
}

// Ward Configs
export const WARD_POINTS = {
    // GET_WARD_POINTS_API: 'https://api.bmapsbd.com/api/ward/places/',
    GET_HOLDING_DETAILS: 'https://bkoih3.ml/api/holdings',
    DATA_ID: 'ward-points',
    DATA_LABEL: 'Holdings',
    COLOR_KEY: 'placeType',
    FILTER_KEY: 'placeType',
    TOOLTIP_FIELDS: [
        {
            name: 'place_name',
            format: null
        },
        {
            name: 'business_name',
            format: null
        },
        {
            name: 'Address',
            format: null
        },
        {
            name: 'pType',
            format: null
        },
        {
            name: 'subType',
            format: null
        },
        {
            name: 'area',
            format: null
        },
        {
            name: 'postCode',
            format: null
        },
        {
            name: 'thana',
            format: null
        },
        {
            name: 'district',
            format: null
        },
        {
            name: 'floors',
            format: null
        },
        {
            name: 'nature',
            format: null
        }
    ],
    COLOR_PALETTE: [
        '#dc143c',
        '#a46cb7',
        '#7aa457'
    ]
}