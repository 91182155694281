import { combineReducers } from 'redux'
import { keplerGlReducer } from 'kepler.gl/reducers'
import navReducer from './navReducer'
import localeReducer from './localeReducer'
import zoneReducer from './zoneReducer'
import wardReducer from './wardReducer'
import dataReducer from './dataReducer'
import authReducer from './authReducer'

const rootReducer = combineReducers({
    keplerGl: keplerGlReducer,
    nav: navReducer,
    locale: localeReducer,
    zone: zoneReducer,
    ward: wardReducer,
    data: dataReducer,
    auth: authReducer
})

export default rootReducer