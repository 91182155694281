import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// Import Components
import App from './App'

// Import Styles
import './index.css'

// Import Redux Store
import store from './store/store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)