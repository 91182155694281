// Generate Place Type Dropdown Options
export function generatePlaceTypeDropdownOptions() {
  // const placeTypeOptions = [
  //   { value: 'Residential', label: 'Residential' },
  //   { value: 'Commercial', label: 'Commercial' },
  //   { value: 'Others', label: 'Others' }
  // ]

  const placeTypeOptions = [
    { value: 'Holding Tax', label: 'Holding Tax' },
    { value: 'Trade License Tax', label: 'Trade License Tax' },
    { value: 'Advertising Tax', label: 'Advertising Tax' },
    { value: 'Wheel Tax', label: 'Wheel Tax' },
    { value: 'Market Salami', label: 'Market Salami' },
    { value: 'Market Rent', label: 'Market Rent' },
    { value: 'Non Motorized Vehicle Fee', label: 'Non Motorized Vehicle Fee' },
    { value: 'Amusement Fee', label: 'Amusement Fee' },
    { value: 'Immovable Property Fee', label: 'Immovable Property Fee' },
    { value: 'City Tax', label: 'City Tax' },
    { value: 'Octroi', label: 'Octroi' }
  ]

  return placeTypeOptions
}