import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Radio, Space } from 'antd'

// Import Actions & Methods
import { setLocale } from '../../store/actions/localeActions'

class LocaleControl extends React.PureComponent {
  state = {
    isHovered: false,
    isLocaleMenu: false
  }

  // Toggle Is Hovered
  _toggleIsHovered = () => {
    const { isHovered } = this.state
    this.setState({ isHovered: !isHovered })
  }

  // Toggle Locale Menu
  _toggleLocaleMenu = () => {
    const { isLocaleMenu } = this.state
    this.setState({ isLocaleMenu: !isLocaleMenu })
  }

  // On Locale Change
  _onChange = e => {
    const { dispatch } = this.props

    // Dispathc Set Locale Action
    dispatch( setLocale(e.target.value) )
  }

  render() {
    const { locale } = this.props
    const { isHovered, isLocaleMenu } = this.state

    return (
      <div style={ containerStyles }>
        { isLocaleMenu &&
          <div style={ menuContainerStyles }>
            <Radio.Group
              value={ locale }
              onChange={ this._onChange }
            >
              <Space direction='vertical'>
                <Radio
                  value='en'
                  style={{
                    color: '#f5f5f5',
                    fontSize: '12px'
                  }}
                >
                  { 'English' }
                </Radio>

                <Radio
                  value='bn'
                  style={{
                    color: '#f5f5f5',
                    fontSize: '12px'
                  }}
                >
                  { 'বাংলা' }
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        }

        <div
          onMouseEnter={ this._toggleIsHovered }
          onMouseLeave={ this._toggleIsHovered }
          onClick={ this._toggleLocaleMenu }
          style={ iconContainerStyles }
        >
          <div
            style={{
              fontSize: '14px',
              color: isHovered || isLocaleMenu ? '#f5f5f5' : '#88909d'
            }}
          >
            { locale === 'bn' ? 'Bn' : 'En' }
          </div>
        </div>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start'
}

const menuContainerStyles = {
  marginRight: '8px',
  padding: '8px 0px 8px 8px',
  background: '#29323c',
  pointerEvents: 'auto',
  maxHeight: '50vh',
  overflow: 'auto'
}

const iconContainerStyles = {
  width: '32px',
  height: '32px',
  padding: '6px',
  background: '#29323c',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'auto',
  cursor: 'pointer'
}

// Prop Types
LocaleControl.propTypes = {
  dispatch: PropTypes.func
}

LocaleControl.defaultProps = {
  dispatch: () => null
}

const mapStateToProps = state => ({
  locale: state.locale.locale
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(LocaleControl)