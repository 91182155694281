import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MAP } from '../../App.config'

// Import Components
import { Radio, Space } from 'antd'
import { SwitcherOutlined } from '@ant-design/icons'

// Import Actions & Methods
import { setCurrentMapStyle } from '../../store/actions/mapActions'

class MapStyleControl extends React.PureComponent {
    state = {
        mapStyle: 'barikoi-light',
        isHovered: false,
        isMapStyleMenu: false
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch } = this.props
        const { mapStyle } = this.state

        // If `mapStyle` changes in state
        if(prevState.mapStyle !== mapStyle) {
            // Change Map Style
            dispatch( setCurrentMapStyle(mapStyle) )
        }
    }

    // Toggle Is Hovered
    _toggleIsHovered = () => {
        const { isHovered } = this.state
        this.setState({ isHovered: !isHovered })
    }

    // Toggle Map Style Menu
    _toggleMapStyleMenu = () => {
        const { isMapStyleMenu } = this.state
        this.setState({ isMapStyleMenu: !isMapStyleMenu })
    }

    // On Map Style Change
    _onChange = e => {
        this.setState({ mapStyle: e.target.value })
    }

    render() {
        const { locale } = this.props
        const { mapStyle, isHovered, isMapStyleMenu } = this.state

        return (
            <div style={ containerStyles }>
                { isMapStyleMenu &&
                    <div style={ menuContainerStyles }>
                        <Radio.Group
                            value={ mapStyle }
                            onChange={ this._onChange }
                        >
                            <Space direction='vertical'>
                                {
                                    MAP.MAP_STYLES.map((m, i) =>
                                        <Radio
                                            key={ i }
                                            value={ m.value }
                                            style={{
                                                color: '#f5f5f5',
                                                fontSize: '12px'
                                            }}
                                        >
                                            { locale === 'bn' ?
                                                m.labelBn
                                                :
                                                m.label
                                            }
                                        </Radio>
                                    )
                                }
                            </Space>
                        </Radio.Group>
                    </div>
                }

                <div
                    onMouseEnter={ this._toggleIsHovered }
                    onMouseLeave={ this._toggleIsHovered }
                    onClick={ this._toggleMapStyleMenu }
                    style={ iconContainerStyles }
                >
                    <SwitcherOutlined
                        style={{
                            fontSize: '20px',
                            color: isHovered || isMapStyleMenu ? '#f5f5f5' : '#88909d'
                        }}
                    />
                </div>
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

const menuContainerStyles = {
    marginRight: '8px',
    padding: '8px 0px 8px 8px',
    background: '#29323c',
    pointerEvents: 'auto',
    maxHeight: '50vh',
    overflow: 'auto'
}

const iconContainerStyles = {
    width: '32px',
    height: '32px',
    padding: '6px',
    background: '#29323c',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
    cursor: 'pointer'
}

// Prop Types
MapStyleControl.propTypes = {
    dispatch: PropTypes.func
}

MapStyleControl.defaultProps = {
    dispatch: () => null
}

const mapStateToProps = state => ({
    locale: state.locale.locale
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(MapStyleControl)