import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'lodash.isequal'

// Import Components
import Dashboard from '../components/Dashboard/Dashboard'
import MenuIcon from '../components/LeftPanel/MenuIcon'
import LeftPanel from '../components/LeftPanel/LeftPanel'
import LinearProgressBar from '../components/common/LinearProgressBar'
import MapPage from './MapPage'

// Import Actions & Methods
import { getAllZoneAreas, generateZoneDropdownOptions } from '../utils/zoneUtils'
import { getAllWardAreas, generateWardDropdownOptions } from '../utils/wardUtils'
import { generatePlaceTypeDropdownOptions } from '../utils/placeTypeUtils'
import { setSelectedZones } from '../store/actions/zoneActions'
import { setWardDropdownOptions, setSelectedWards, loadSelectedWardPoints } from '../store/actions/wardActions'
import { setIsLoading, fetchLatestUploadedFiles, uploadZoneWardData } from '../store/actions/dataActions'

class UrbanEngine extends React.PureComponent {
    state = {
        isLeftPanelOpen: true,
        zoneAreas: null,
        wardAreas: null,
        zoneDropdownOptions: [],
        placeTypeOptions: [],
        selectedPlaceTypes: [ 'All' ]
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedTabKey, selectedWards, dispatch } = this.props
        const { zoneAreas, wardAreas } = this.state

        // If `selectedTabKey` changes in props
        if(prevProps.selectedTabKey !== selectedTabKey) {
            // Load Initial Map Data
            if(selectedTabKey === '2') {
                // if(!zoneAreas || !wardAreas) {
                //     this._getZoneAndWardAreas()
                // }

                this._getZoneAndWardAreas()
            }
        }

        // // If `selectedWards` changes in props
        // if(!_isEqual(prevProps.selectedWards, selectedWards)) {
        //     dispatch( loadSelectedWardPoints(selectedWards) )
        // }

        // If zoneAreas or wardAreas states change in state
        if((prevState?.zoneAreas === null && zoneAreas) && (prevState?.wardAreas === null && wardAreas)) {
            // Fetch Latest Uploaded Files
            fetchLatestUploadedFiles()
            .then(filePaths => {
                filePaths.forEach(f => {
                    dispatch( uploadZoneWardData(f) )
                })
            })
            .catch(err => {
                // Pass
            })
        }
    }

    // Toggle Is Left Panel Open
    _toggleIsLeftPanelOpen = () => {
        const { isLeftPanelOpen } = this.state
        this.setState({ isLeftPanelOpen: !isLeftPanelOpen })
    }

    // On Zone Change
    _onZoneChange = newSelectedZones => {
        const { dispatch } = this.props

        let selectedZones = newSelectedZones
        const length = selectedZones.length

        // If All Selected
        if(length !== 0 && selectedZones[ length-1 ] === 'All') {
            selectedZones = [ 'All' ]

        } else if(length !== 0 && selectedZones[ length-1 ] !== 'All' && selectedZones.includes('All')) {
            selectedZones = selectedZones.filter(s => s !== 'All')
        }

        // Single selection
        // selectedZones = selectedZones.slice(selectedZones.length - 1)

        // Set Selected Zones
        dispatch( setSelectedZones(selectedZones) )
    }

    // On Ward Change
    _onWardChange = newSelectedWards => {
        const { dispatch } = this.props
        let selectedWards = newSelectedWards
        const length = selectedWards.length

        // If All Selected
        if(length !== 0 && selectedWards[ length-1 ] === 'All') {
            selectedWards = [ 'All' ]

        } else if(length !== 0 && selectedWards[ length-1 ] !== 'All' && selectedWards.includes('All')) {
            selectedWards = selectedWards.filter(s => s !== 'All')
        }

        // Single selection
        // selectedWards = selectedWards.slice(selectedWards.length - 1)

        // Set Selected Wards
        dispatch( setSelectedWards(selectedWards) )
    }

    // On Place Type Change
    _onPlaceTypeChange = newSelectedPlaceTypes => {
        let selectedPlaceTypes = newSelectedPlaceTypes
        const length = selectedPlaceTypes.length

        // If All Selected
        if(length !== 0 && selectedPlaceTypes[ length-1 ] === 'All') {
            selectedPlaceTypes = [ 'All' ]

        } else if(length !== 0 && selectedPlaceTypes[ length-1 ] !== 'All' && selectedPlaceTypes.includes('All')) {
            selectedPlaceTypes = selectedPlaceTypes.filter(s => s !== 'All')
        }

        this.setState({ selectedPlaceTypes })
    }

    // Get Zone & Ward Areas
    _getZoneAndWardAreas = () => {
        const { dispatch } = this.props

        // Set isLoading
        dispatch( setIsLoading(true) )

        // Load Initial Data To Map
        Promise.all([
            getAllZoneAreas(),
            getAllWardAreas()
        ])
            .then(results => {
                const zoneAreas = results[0]
                const wardAreas = results[1]

                // Generate Zone Dropdown Options
                const zoneDropdownOptions = generateZoneDropdownOptions(zoneAreas)

                // Generate Ward Dropdown List
                const wardDropdownOptions = generateWardDropdownOptions(wardAreas)

                // Generate Place Type Options
                const placeTypeOptions = generatePlaceTypeDropdownOptions()

                this.setState({ zoneAreas: null, wardAreas: null })
                this.setState({
                    zoneAreas,
                    wardAreas,
                    zoneDropdownOptions: [
                        { value: 'All', label: 'All' },
                        ...zoneDropdownOptions
                    ],
                    placeTypeOptions: [
                        { value: 'All', label: 'All' },
                        ...placeTypeOptions
                    ]
                })

                // Set wardDropdownOptions
                dispatch( setWardDropdownOptions([ ...wardDropdownOptions ]) )

                // Set isLoading
                dispatch( setIsLoading(false) )
        
            })
            .catch(err => {
                console.error(err)

                // Set isLoading
                dispatch( setIsLoading(false) )
            })
    }

    // Filter Ward Dropdown Options Based On Selected Zones
    _filterWardDropdownOptions = (_wardDropdownOptions, selectedZones) => {
        if(selectedZones.includes('All')) {
            return _wardDropdownOptions
        }

        const wardDropdownOptions = _wardDropdownOptions.filter(w =>
            selectedZones.includes(w.label)
        )

        return wardDropdownOptions
    }

    render() {
        const { selectedTabKey, selectedZones, wardDropdownOptions, selectedWards, wardPoints, isLoading } = this.props
        const { isLeftPanelOpen, zoneAreas, wardAreas, zoneDropdownOptions, placeTypeOptions, selectedPlaceTypes } = this.state

        return (
            <div style={ containerStyles }>
                { selectedTabKey === '1' &&
                    <div
                        style={{
                            marginLeft: isLeftPanelOpen ? '280px' : '0px'
                        }}
                    >
                        <Dashboard />
                    </div>
                }

                { selectedTabKey === '2' &&
                    <div
                        style={{
                            marginLeft: isLeftPanelOpen ? '280px' : '0px',
                            padding: '1rem',
                            height: '100%'
                        }}
                    >
                        <MapPage
                            zoneAreas={ zoneAreas }
                            wardAreas={ wardAreas }
                            wardPoints={ wardPoints }
                            selectedZones={ selectedZones }
                            selectedWards={ selectedWards }
                            selectedPlaceTypes={ selectedPlaceTypes }
                        />
                    </div>
                }

                { !isLeftPanelOpen &&
                    <MenuIcon
                        onClick={ this._toggleIsLeftPanelOpen }
                    />
                }

                <LeftPanel
                    title={ 'Urban Engine' }
                    onClose={ this._toggleIsLeftPanelOpen }
                    visible={ isLeftPanelOpen }
                    zoneDropdownOptions={ zoneDropdownOptions }
                    wardDropdownOptions={ (() => {
                        return this._filterWardDropdownOptions(wardDropdownOptions, selectedZones)
                    })() }
                    placeTypeOptions={ placeTypeOptions }
                    selectedZones={ selectedZones }
                    selectedWards={ selectedWards }
                    selectedPlaceTypes={ selectedPlaceTypes }
                    onZoneChange = { this._onZoneChange }
                    onWardChange={ this._onWardChange }
                    onPlaceTypeChange={ this._onPlaceTypeChange }
                    wardPoints={ wardPoints }
                    isLoading={ isLoading }
                />

                { isLoading &&
                    <LinearProgressBar />
                }
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%'
}

// Prop Types
UrbanEngine.propTypes = {
    selectedTabKey: PropTypes.string,
    selectedZones: PropTypes.array,
    wardDropdownOptions: PropTypes.array,
    selectedWards: PropTypes.array,
    wardPoints: PropTypes.object,
    isLoading: PropTypes.bool,
    dispatch: PropTypes.func
}

UrbanEngine.defaultProps = {
    selectedTabKey: '1',
    selectedZones: [],
    wardDropdownOptions: [],
    selectedWards: [],
    wardPoints: null,
    isLoading: false,
    dispatch: () => null
}

const mapStateToProps = state => ({
    selectedTabKey: state.nav.selectedTabKey,
    selectedZones: state.zone.selectedZones,
    wardDropdownOptions: state.ward.wardDropdownOptions,
    selectedWards: state.ward.selectedWards,
    wardPoints: state.ward.wardPoints,
    isLoading: state.data.isLoading
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(UrbanEngine)