import { layerVisConfigChange, layerVisualChannelConfigChange } from 'kepler.gl/actions'
import { ZONE_AREAS, WARD_AREAS } from '../../App.config'

// Set Layer Size with Zoom Level
export function setLayerSizeWithZoomLevel(zoom) {
  return (dispatch, getState) => {
    const { map } = getState().keplerGl
    if(map) {
      const { layers } = map.visState
      const radius = getRadiusWithZoom(zoom)
      layers.forEach(l => {
        if(l.config.dataId !== ZONE_AREAS.CENTER_DATA_ID && l.config.dataId !== WARD_AREAS.CENTER_DATA_ID) {
          dispatch( layerVisConfigChange(l, { radius }) )
        }
      })
    }
  }
}

// Get Radius with Zoom
function getRadiusWithZoom(zoom) {
  let radius = 14

  if(zoom >= 14 && zoom < 15) {
    radius = 12

  } else if(zoom >= 15 && zoom < 16) {
    radius = 7.3

  } else if(zoom >= 16 && zoom < 17) {
    radius = 3.7

  } else if(zoom >= 17 && zoom < 18) {
    radius = 2.3

  } else if(zoom >= 18 && zoom < 19) {
    radius = 1.1

  } else if(zoom >= 19 && zoom < 20) {
    radius = 0.8

  } else if(zoom >= 20 && zoom < 24) {
    radius = 0.4

  } else {
    radius = 14
  }

  return radius
}

// Get Clicked Object Layer Data Id
export function getClickedObjectLayerDataId(clicked, layers) {
  if(!clicked) {
    return ''
  }

  const clickedLayer = layers.find(l => l.id === clicked.layer.id)
  if(clickedLayer) {
    return clickedLayer.config.dataId

  } else {
    return ''
  }
}

// Layer Beep Based On Key
export function beepLayerBy(layer, key) {
  return (dispatch, getState) => {
    // Set Size Field By To Activate Beeping Feature
    const dataset = getState().keplerGl.map.visState.datasets[ layer.config.dataId ]
    const sizeField = dataset.fields.find(el => el.name === key)

    // Dispatch Color Field By
    dispatch( layerVisualChannelConfigChange(layer, { sizeField }, 'size') )

    // Change Radius Periodically to Simulate Beeping Effect
    let radiusDiff = 0
    let radiusMaxFactor = 2
    let changeType = 'inc'
    let step = 0.2
    setInterval(() => {
      const { map } = getState().keplerGl
      if(map) {
        const { layers } = map.visState
        layers.forEach(l => {
          if(l.config.dataId === layer.config.dataId) {
            const currentRadius = l.config.visConfig.radius

            if(radiusDiff <= 0) {
              changeType = 'inc'
      
            } else if(radiusDiff >= ((radiusMaxFactor * currentRadius) - currentRadius)) {
              changeType = 'dec'
            }

            step = currentRadius / 100
      
            if(changeType === 'inc') {
              radiusDiff += step
      
            } else if(changeType === 'dec') {
              radiusDiff -= step
            }
            
            const radiusRange = [ currentRadius, currentRadius + radiusDiff ]
            dispatch( layerVisConfigChange(layer, { radiusRange }) )
          }
        })
      }
    }, 4)
  }
}