import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ZONE_AREAS, WARD_AREAS, WARD_POINTS } from '../../App.config'

// Import Components
import { Typography } from 'antd'
import HoldingDetails from './HoldingDetails'
import ZoneDetails from './ZoneDetails'
import WardDetails from './WardDetails'
import TargetChart from '../Dashboard/TargetChart'
import RevenueChart from '../Dashboard/RevenueChart'

// Import Actions & Methods
import { getHoldingDetails } from '../../utils/wardUtils'
import { getClickedObjectLayerDataId } from '../../store/actions/layerActions'

// Constants
const { Title } = Typography

class RightPanel extends React.PureComponent {
    state = {
        eHoldings: []
    }

    // componentDidMount() {
    //     // Get eHolding Details
    //     getHoldingDetails('LYNJ4084')
    //         .then(res => {
    //             this.setState({ eHoldings: res.holdings })
    //         })
    //         .catch(err => {
    //             console.error(err)
    //         })
    // }

    render() {
        const { clicked, layers } = this.props
        const { eHoldings } = this.state

        return (
            <div style={ containerStyles }>
                <Title
                    level={ 5 }
                    style={{
                        margin: '0px',
                        marginBottom: '1rem',
                        color: '#29323c'
                    }}
                >
                    { clicked && clicked.object && clicked.object.properties ?
                        clicked.object.properties.ward_number ?
                        clicked.object.properties.ward_number
                        :
                        clicked.object.properties.zone_number
                        :
                        'Details'
                    }
                </Title>
                
                { (clicked && getClickedObjectLayerDataId(clicked, layers) === ZONE_AREAS.DATA_ID) ?
                    (
                        <ZoneDetails clicked={ clicked } />
                    )
                    :
                    (clicked && getClickedObjectLayerDataId(clicked, layers) === WARD_AREAS.DATA_ID) ?
                    (
                        <WardDetails clicked={ clicked } />
                    )
                    :
                    (clicked && getClickedObjectLayerDataId(clicked, layers) === WARD_POINTS.DATA_ID) ?
                    (
                        <HoldingDetails
                            eHoldings={ eHoldings }
                            clicked={ clicked }
                        />
                    )
                    :
                    (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch', rowGap: '1rem' }}>
                            <TargetChart />
                            <RevenueChart />
                        </div>
                    )
                }
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
}

// Prop Types
RightPanel.propTypes = {
    clicked: PropTypes.object,
    layers: PropTypes.array
}

RightPanel.defaultProps = {
    clicked: null,
    layers: []
}

const mapStateToProps = state => ({
    clicked: state?.keplerGl?.map?.visState?.clicked ?? null,
    layers: state?.keplerGl?.map?.visState?.layers ?? []
})

export default connect(mapStateToProps)(RightPanel)