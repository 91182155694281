import * as ActionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false
}

const dataReducer = (state=initialState, action) => {
  switch(action.type) {
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    default:
      return state
  }
}

export default dataReducer