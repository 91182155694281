import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Typography, Select } from 'antd'

class WardSelectMenu extends React.PureComponent {
    render() {
        const { wardDropdownOptions, selectedWards, onChange, locale, disabled } = this.props

        return (
            <div style={ containerStyles }>
                <Typography.Title
                    level={ 5 }
                    type='secondary'
                >
                    { locale === 'bn' ?
                        'ওয়ার্ড নির্বাচন করুন'
                        :
                        'Select Ward(s)'
                    }
                </Typography.Title>

                <Select
                    mode='multiple'
                    allowClear={ true }
                    options={ wardDropdownOptions }
                    optionLabelProp='label'
                    optionFilterProp='label'
                    placeholder={
                        locale === 'bn' ?
                        'ওয়ার্ড নির্বাচন করুন'
                        :
                        'Select Ward(s)...'
                    }
                    showArrow={ true }
                    loading={ false }
                    disabled={ disabled }
                    value={ selectedWards }
                    onChange={ onChange }
                    style={{ width: '100%' }}
                />
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

// Prop Types
WardSelectMenu.propTypes = {
    wardDropdownOptions: PropTypes.array,
    selectedWards: PropTypes.array,
    onChange: PropTypes.func,
    locale: PropTypes.string,
    disabled: PropTypes.bool
}

WardSelectMenu.defaultProps = {
    wardDropdownOptions: [],
    selectedWards: [],
    onChange: () => null,
    locale: 'en',
    disabled: false
}

const mapStateToProps = state => ({
    locale: state.locale.locale
})

export default connect(mapStateToProps, null)(WardSelectMenu)