import axios from 'axios'
import { WARD_AREAS, WARD_POINTS } from '../App.config'

// Get All Ward Areas
export function getAllWardAreas() {
  return axios.get(WARD_AREAS.GET_WARD_AREAS_API, { timeout: 60000 })
    .then(res => {
      const wardAreaGeoJson = {
        type: 'FeatureCollection',
        features: res.data.features.filter(f => f.properties.zone_number.includes('DNCC')).map(w => {
          const target_achieved = Math.floor(Math.random() * 100)
          const target_achieved_status = target_achieved > 79 ? '80% - 100%' : target_achieved > 39 ? '40% - 79%' : '0% - 39%'

          const feature = {
            type: 'Feature',
            properties: { ...w.properties, target_achieved: `${ target_achieved }%`, target_achieved_status },
            geometry: w.geometry
          }

          return feature
        })
      }

      return wardAreaGeoJson
    })
    .catch(err => {
      throw err.response && err.response.message ? err.response.message : err
    })
}

// Generate Ward Dropdown Options
export function generateWardDropdownOptions(wardGeoJson) {
  // From Ward Area GeoJSON List
  const { features } = wardGeoJson
  let wardOptions = [ { value: 'All', label: 'All' } ]

  let index = -1
  for(let i = 0; i < features.length; i++) {
    const { properties } = features[i]
    index = wardOptions.findIndex(el => el.label === properties.zone_number)

    // If element not found
    if(index === -1) {
      wardOptions.push({
        label: properties.zone_number,
        options: [{
          value: properties.ward_number,
          label: properties.ward_number,
          ...properties
        }]
      })

    } else {
      wardOptions[index].options.push({
        value: properties.ward_number,
        label: properties.ward_number,
        ...properties
      })
    }
  }

  // Sort Menu Groups by city_corp and zone_number
  wardOptions.sort((a, b) => {
    let labelA = a.label.toLowerCase().split(' ')[0]
    let labelB = b.label.toLowerCase().split(' ')[0]

    // Sort by city_corp
    if(labelA < labelB) {
      return -1

    } else if(labelA > labelB) {
      return 1

    } else {
      // Sort by zone_number
      labelA = parseInt(a.label.split('-')[1])
      labelB = parseInt(b.label.split('-')[1])

      if(labelA < labelB) {
        return -1

      } else if(labelA > labelB) {
        return 1

      } else {
        return 0
      }
    }
  })

  // Sort Each Options by value
  wardOptions.forEach(group => {
    if(group.label !== 'All') {
      // Sort Options in Group
      group.options.sort((a, b) => {
        const labelA = parseInt(a.label.split(' ')[0].split('-')[1])
        const labelB = parseInt(b.label.split(' ')[0].split('-')[1])

        if(labelA < labelB) {
          return -1

        } else if(labelA > labelB) {
          return 1

        } else {
          return 0
        }
      })
    }
  })

  // Filter Out only DNCC Options
  wardOptions = wardOptions.filter(w => w.label === 'All' || w.label.includes('DNCC'))
  
  return wardOptions
}

// Get Ward Points Data and Add to Map
export function getWardPointsData(wardId, config) {
  return axios.get(WARD_POINTS.GET_WARD_POINTS_API + wardId, config)
    .then(res => {
      // Create GeoJson
      const wardPoints = {
        type: 'FeatureCollection',
        features: []
      }

      if(res.data.data) {
        const { places } = res.data.data
        if(places) {
          wardPoints.features = places.map(p => ({
            type: 'Feature',
            properties: {
              ...Object.fromEntries(Object.entries(p)
                .filter(e => e[0] !== 'longitude' && e[0] !== 'latitude')),
              floors: p?.floor_level_flat?.length ?
                p.floor_level_flat.find(f => f.key === 'floor' && f.value)?.value ?? 'N/A' :
                'N/A',
              nature: p?.characteristics?.length ?
                p.characteristics.find(f => f.key === 'nature' && f.value)?.value ?? 'N/A' :
                'N/A'
            },
            geometry: {
              type: 'Point',
              coordinates: [ p.longitude, p.latitude ]
            }
          }))
        }
      }

      return wardPoints
    })
    .catch(err => {
      throw err.response && err.response.message ? err.response.message : err
    })
}

// Transform Ward Points to activate beeping effect based on key-value
export function transformWardPointsForBeepingEffect(wardPoints, key, value) {
  const _wardPoints = wardPoints.features.map(f => ({
    ...f.properties,
    longitude: f.geometry.coordinates[0],
    latitude: f.geometry.coordinates[1],
    beep: f.properties[ key ] === value ? true : false
  }))

  return _wardPoints
}

// Get Holding Details by uCode
export function getHoldingDetails(uCode) {
  return axios.get(WARD_POINTS.GET_HOLDING_DETAILS, { params: { uCode } })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err.response && err.response.message ? err.response.message : err
    }) 
}