import * as ActionTypes from '../actions/actionTypes'

const initialState = {
  selectedTabKey: '1'
}

const navReducer = (state=initialState, action) => {
  switch(action.type) {
    case ActionTypes.SET_SELECTED_TAB_KEY:
      return {
        ...state,
        selectedTabKey: action.payload
      }

    default:
      return state
  }
}

export default navReducer