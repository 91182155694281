import { AuthActionTypes } from '../actions/actionTypes'

const initialState = {
  isAuthenticated: true,
  isValidating: false,
  email: '',
  password: '',
  token: '',
  user: {},
  error: ''
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload
      }
    case AuthActionTypes.SET_IS_VALIDATING:
      return {
        ...state,
        isValidating: action.payload
      }
    case AuthActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case AuthActionTypes.SET_PASSWORD:
      return {
        ...state,
        password: action.payload
      }
    case AuthActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }
    case AuthActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case AuthActionTypes.SET_AUTH_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export default authReducer