import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Form, Typography, Input, Button, Row, Col } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'

// Import Actions & Methods
import { login } from '../store/actions/authActions'

// Import Assets
import loginCover from '../assets/login_cover.jpg'

class Login extends React.PureComponent {
  // On Form Submit
  _onSubmit = values => {
    const { dispatch } = this.props
    
    // Dispatch Login Action
    dispatch( login(values) )
  }

  // On Submit Error
  _onSubmitError = err => {
    console.error(err)
  }

  render() {
    return (
      <div style={{ height: '100vh', width: '100vw', padding: '1rem' }}>                
        <Row justify={ 'center' } align={ 'middle' } style={{ height: '100%', width: '100%', }}>
          <Col xs={ 0 } sm={ 24 } md={ 12 } lg={ 16 }>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={ loginCover } alt={ 'Cover' } style={{ objectFit: 'cover', maxHeight: '100%', maxWidth: '100%' }} />
            </div>
          </Col>
          <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } >
            <div style={ containerStyles }>
              <Form
                layout='vertical'
                size='large'
                autoComplete='off'
                onFinish={ this._onSubmit }
                onFinishFailed={ this._onSubmit }
                validateTrigger={ 'onChange' }
                style={ shadowStyles }
              >
                <Form.Item>
                  <Typography.Title
                    level={ 2 }
                    style={{ textAlign: 'center' }}
                  >
                    { 'Urban Engine' }
                  </Typography.Title>
                </Form.Item>

                <Form.Item
                  label='Email'
                  name='email'
                  hasFeedback={ true }
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email.'
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address.'
                    }
                  ]}
                >
                  <Input style={{ borderLeft: '4px solid #1890ff' }} prefix={ <MailOutlined style={{ color: '#1890ff' }} /> } />
                </Form.Item>

                <Form.Item
                  label='Password'
                  name='password'
                  hasFeedback={ true }
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password.'
                    },
                    {
                      min: 6,
                      message: 'Password must be at least 6 characters.'
                    }
                  ]}
                >
                  <Input.Password style={{ borderLeft: '4px solid #1890ff' }} prefix={ <LockOutlined style={{ color: '#1890ff' }} /> } />
                </Form.Item>

                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{ width: '100%' }}
                  >
                    { 'Login' }
                  </Button>
                </Form.Item>
              </Form>
            </div>        
          </Col>
        </Row>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const shadowStyles = {
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)',
  padding: '2rem 2rem 1rem',
  borderRadius: '2px'
}

// Prop Types
Login.propTypes = {
  dispatch: PropTypes.func
}

Login.defaultProps = {
  dispatch: () => null
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(Login)