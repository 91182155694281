import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Row, Col, Card, Statistic } from 'antd'

class ZoneDetails extends React.PureComponent {
  render() {
    const { clicked } = this.props

    const data = clicked && clicked.object && clicked.object.properties ?
      clicked.object.properties
      :
      {}

    return (
      <div style={ containerStyles }>
        <div style={{ padding: '1rem', width: '100%', borderRadius: '4px', border: '1px solid #f0f0f0' }}>
          <Row
            gutter={[ 16, 16 ]}
            style={{
              margin: 0,
              width: '100%'
            }}
          >
            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Holdings Revenue'
                  value={ data.holdings_revenue }
                  suffix={
                    <span>{ 'Cr' }</span>
                  }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='TL Revenue'
                  value={ data.tl_revenue }
                  suffix={
                    <span>{ 'Cr' }</span>
                  }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Total Holdings'
                  value={ data.total_holdings }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Total TL'
                  value={ data.total_tl }
                  valueStyle={{
                    color: '#2ddbac',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Unpaid Holdings'
                  value={ data.unpaid_holdings }
                  valueStyle={{
                    color: '#c70039',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>

            <Col xs={ 24 } sm={ 12 }>
              <Card bodyStyle={{ padding: '8px' }} style={ cardHoveredStyles }>
                <Statistic
                  title='Unpaid TL'
                  value={ data.unpaid_tl }
                  valueStyle={{
                    color: '#c70039',
                    fontSize: '20px'
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
  overflow: 'auto'
}

const cardHoveredStyles = {
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
}

// Prop Types
ZoneDetails.propTypes = {
  clicked: PropTypes.object
}

ZoneDetails.defaultProps = {
  clicked: null
}

export default ZoneDetails