import axios from 'axios'
import { AuthActionTypes } from './actionTypes'
import { AUTH } from '../../App.config'

// Get auth token from local storage
export function getAuthToken() {
  const authToken = localStorage.getItem('token')
  return authToken ?? ''
}

// Set Is Authenticated
export function setIsAuthenticated(isAuthenticated) {
  return dispatch =>  {
    dispatch({
      type: AuthActionTypes.SET_IS_AUTHENTICATED,
      payload: isAuthenticated
    })
  }
}

// Set Is Validating
export function setIsValidating(isValidating) {  
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_IS_VALIDATING,
      payload: isValidating
    })
  }
}

// Set Email
export function setEmail(email) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_EMAIL,
      payload: email
    })
  }
}

// Set Password
export function setPassword(password) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_PASSWORD,
      payload: password
    })
  }
}

// Set Token
export function setToken(token) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_TOKEN,
      payload: token
    })
  }
}

// Set User
export function setUser(user) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_USER,
      payload: user
    })
  }
}

// Set Is Auth Error
export function setAuthError(authError) {  
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_AUTH_ERROR,
      payload: authError
    })
  }
}

///// LOGIN /////
// Login Action
export function login(user) {
  return dispatch => {
    // Set `isValidating`
    dispatch( setIsValidating(true) )

    axios.post(AUTH.LOGIN_API, user)
      .then(res => {
        if(res.data && res.data.data) {
          const token = res.data.data                  

          // Validate User
          dispatch( validateUser(token) )
            
        } else {
          throw new Error('Token Not Found')
        }
      })
      .catch(err => {
        console.error(err)

        // Dispatch `authReducer` Values to Redux State
        dispatch( setIsAuthenticated(false) )
        dispatch( setToken('') )
        dispatch( setUser({}) )
        dispatch( setAuthError(err?.response?.data?.message ?? err?.message ?? '') )

        // Set `isValidating`
        dispatch( setIsValidating(false) )
      })
  }
}

// User Validation
export function validateUser(token) {
  return dispatch => {
    // Set `isValidating`
    dispatch( setIsValidating(true) )

    axios.get(AUTH.GET_USER_API, { headers: { Authorization: `Bearer ${ token }` } })
      .then(res => {
        if(res.data.user) {
          const user = res.data.user                  

          // Save `token` & `user` to localStorage
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))

          // Dispatch authReducer Values to Redux State
          dispatch( setIsAuthenticated(true) )
          dispatch( setToken(token) )
          dispatch( setUser(user) )
          dispatch( setAuthError('') )
          dispatch( setEmail('') )
          dispatch( setPassword('') )                  

          // Set `isValidating`
          dispatch( setIsValidating(false) )
        }

        // Set `isValidating`
        dispatch( setIsValidating(false) )

      })
      .catch(err => {
        console.error(err)

        // Dispatch authReducer Values to Redux State
        dispatch( setIsAuthenticated(false) )
        dispatch( setToken('') )
        dispatch( setUser({}) )
        dispatch( setAuthError(err?.response?.data?.message ?? err?.message ?? '') )

        // If no token in `localStorage`
        if(!localStorage.getItem('token')) {
          dispatch( setAuthError('') )
        }

        // Clear localStorage
        localStorage.clear()

        // Set `isValidating`
        dispatch( setIsValidating(false) )
      })
  }
}

// Logout Action
export function logout() {
  return dispatch => {
    // Set `isValidating`
    dispatch( setIsValidating(true) )

    // Clear localStorage
    localStorage.clear()

    // Dispatch `authReducer` Values to Redux State
    dispatch( setIsAuthenticated(false) )
    dispatch( setToken('') )
    dispatch( setUser({}) )
    dispatch( setAuthError('') )

    // Set `isValidating`
    dispatch( setIsValidating(false) )
  }
}