import React from 'react'

// Import Components
import { Row, Col, Card, Statistic } from 'antd'
import TargetChart from './TargetChart'
import RevenueChart from './RevenueChart'

class Dashboard extends React.PureComponent {
  render() {
    return (
      <div style={ containerStyles }>
        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Target (2021 - 2022)'
                value={ 500 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#ffa500'
                }}
              />
            </Card>
          </Col>

          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Achieved (Upto Today)'
                value={ 10 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#2ddbac'
                }}
              />
            </Card>
          </Col>

          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Achieved (2021 - 2022)'
                value={ 220 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#2ddbac'
                }}
              />
            </Card>
          </Col>

          <Col xs={ 24 } sm={ 6 }>
            <Card style={ cardHoveredStyles }>
              <Statistic
                title='Remaining (2021 - 2022)'
                value={ 280 }
                suffix={
                  <span>{ 'Cr' }</span>
                }
                valueStyle={{
                  color: '#c70039'
                }}
              />
            </Card>
          </Col>
        </Row>

        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 }>
            <Card hoverable={ true } style={{ ...cardHoveredStyles, width: '100%' }}>
              <Row
                gutter={[ 32, 32 ]}
                justify='space-between'
                style={{
                  width: '100%',
                  marginBottom: '16px'
                }}
              >
                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Total Holdings'
                      value={ 150000 }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                    />
                  </Card>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Total Trade Licenses'
                      value={ 200000 }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row
                gutter={[ 32, 32 ]}
                justify='space-between'
                style={{
                  width: '100%',
                  marginBottom: '16px'
                }}
              >
                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Achieved From Holdings'
                      value={ '65%' }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                    />
                  </Card>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Achieved From Trade Licenses'
                      value={ '35%' }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row
                gutter={[ 32, 32 ]}
                justify='space-between'
                style={{
                  width: '100%',
                  marginBottom: '16px'
                }}
              >
                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Newly Added Holdings (Jan 06, 2022)'
                      value={ 1000 }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                    />
                  </Card>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                  <Card hoverable={ true }>
                    <Statistic
                      title='Newly Added Trade Licenses (Jan 06, 2022)'
                      value={ 1500 }
                      valueStyle={{
                        color: '#2ddbac'
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row
          gutter={[ 32, 32 ]}
          justify='space-between'
          style={{
            width: '100%',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          <Col xs={ 24 }>
            <Card style={ cardHoveredStyles }>
              <TargetChart />
            </Card>
          </Col>

          <Col xs={ 24 }>
            <Card style={ cardHoveredStyles }>
              <RevenueChart />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: '32px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const cardHoveredStyles = {
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
}

export default Dashboard