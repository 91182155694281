import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Row, Col, Card } from 'antd'
import ErrorBoundary from '../components/common/ErrorBoundary'
import MapGL from '../components/Map/MapGL'
import RightPanel from '../components/RightPanel/RightPanel'

class MapPage extends React.PureComponent {
  render() {
    const { zoneAreas, wardAreas, wardPoints, selectedZones, selectedWards, selectedPlaceTypes } = this.props

    return (
      <div style={ containerStyles }>
        <Row gutter={[ 16, 16 ]} style={{ width: '100%', height: '100%' }}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} style={{ maxHeight: '100%' }}>
            <Card
              size='small'
              bordered={ false }
              bodyStyle={ cardBodyStyles }
              style={ elevatedCardStyles }
            >
              <ErrorBoundary>
                <MapGL
                  zoneAreas={ zoneAreas }
                  wardAreas={ wardAreas }
                  wardPoints={ wardPoints }
                  selectedZones={ selectedZones }
                  selectedWards={ selectedWards }
                  selectedPlaceTypes={ selectedPlaceTypes }
                />
              </ErrorBoundary>
            </Card>
          </Col>
          {/* <Col xs={{ span: 24 }} md={{ span: 10 }} style={{ maxHeight: '100%' }}>
            <Card
              size='small'
              bordered={ false }
              bodyStyle={ cardBodyStyles }
              style={ elevatedCardStyles }
            >
              <RightPanel />
            </Card>
          </Col> */}
        </Row>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%'
}

const elevatedCardStyles = {
  width: '100%',
  height: '100%',
  borderColor: 'transparent',
  boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)'
}

const cardBodyStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  alignItems: 'stretch'
}

// Prop Types
MapPage.propTypes = {
  zoneAreas: PropTypes.object,
  wardAreas: PropTypes.object,
  wardPoints: PropTypes.object,
  selectedZones: PropTypes.array,
  selectedWards: PropTypes.array,
  selectedPlaceTypes: PropTypes.array
}

MapPage.defaultProps = {
  zoneAreas: null,
  wardAreas: null,
  wardPoints: null,
  selectedZones: [],
  selectedWards: [],
  selectedPlaceTypes: []
}

export default MapPage