import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Drawer, Typography } from 'antd'
import LeftPanelBody from './LeftPanelBody'

class LeftPanel extends React.PureComponent {
    render() {
        const { visible, title, onClose, zoneDropdownOptions, wardDropdownOptions, placeTypeOptions, selectedZones, selectedWards, selectedPlaceTypes, onZoneChange, onWardChange, onPlaceTypeChange, wardPoints, isLoading, ...restProps } = this.props

        return (
            <Drawer
                visible={ visible }
                width={ 280 }
                title={
                    <Typography.Title
                        level={ 4 }
                        style={{
                            margin: '0px',
                            color: '#29323c'
                        }}
                    >
                        { 'Urban Engine' }
                    </Typography.Title>
                }
                footer={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography.Text
                            italic={ true }
                            style={{
                                fontSize: '11px'
                            }}
                        >
                            { 'Developed by |' }
                        </Typography.Text>

                        <Typography.Link
                            href='https://www.barikoi.com/'
                            target='_blank'
                            style={{
                                marginLeft: '4px',
                                fontSize: '11px'
                            }}
                        >
                            { 'Barikoi Technologies Ltd.' }
                        </Typography.Link>
                    </div>
                }
                headerStyle={{ background: '#f5f5f5' }}
                footerStyle={{ background: '#f5f5f5' }}
                bodyStyle={{ padding: '0px' }}
                placement='left'
                closable={ true }
                onClose={ onClose }
                mask={ false }
                { ...restProps }
            >
                <LeftPanelBody
                    zoneDropdownOptions={ zoneDropdownOptions }
                    wardDropdownOptions={ wardDropdownOptions }
                    placeTypeOptions={ placeTypeOptions }
                    selectedZones={ selectedZones }
                    selectedWards={ selectedWards }
                    selectedPlaceTypes={ selectedPlaceTypes }
                    onZoneChange={ onZoneChange }
                    onWardChange={ onWardChange }
                    onPlaceTypeChange={ onPlaceTypeChange }
                    wardPoints={ wardPoints }
                    isLoading={ isLoading }
                />
            </Drawer>
        )
    }
}

// Prop Types
LeftPanel.propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    zoneDropdownOptions: PropTypes.array,
    wardDropdownOptions: PropTypes.array,
    placeTypeOptions: PropTypes.array,
    selectedZones: PropTypes.array,
    selectedWards: PropTypes.array,
    selectedPlaceTypes: PropTypes.array,
    onZoneChange: PropTypes.func,
    onWardChange: PropTypes.func,
    onPlaceTypeChange: PropTypes.func,
    wardPoints: PropTypes.object,
    isLoading: PropTypes.bool
}

LeftPanel.defaultProps = {
    visible: true,
    title: '',
    onClose: () => null,
    zoneDropdownOptions: [],
    wardDropdownOptions: [],
    placeTypeOptions: [],
    selectedZones: [],
    selectedWards: [],
    selectedPlaceTypes: [],
    onZoneChange: () => null,
    onWardChange: () => null,
    onPlaceTypeChange: () => null,
    wardPoints: null,
    isLoading: false
}

export default LeftPanel