import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Tabs, Button } from 'antd'
import { ProjectOutlined, EnvironmentOutlined, LogoutOutlined } from '@ant-design/icons'
import TabsMenu from './TabsMenu'
import HomeTabPane from './HomeTabPane'

// Import Actions & Methods
import { logout } from '../../store/actions/authActions'

class LeftPanelBody extends React.PureComponent {
    // On Logout
    _onLogout = () => {
        const { dispatch } = this.props
        dispatch( logout() )
    }

    render() {
        const { zoneDropdownOptions, wardDropdownOptions, placeTypeOptions, selectedZones, selectedWards, selectedPlaceTypes, onZoneChange, onWardChange, onPlaceTypeChange, wardPoints, isLoading } = this.props

        return (
            <div style={ containerStyles }>
                <TabsMenu>
                    <Tabs.TabPane tab={ <ProjectOutlined /> } key='1'>
                        <HomeTabPane
                            zoneDropdownOptions={ zoneDropdownOptions }
                            wardDropdownOptions={ wardDropdownOptions }
                            placeTypeOptions={ placeTypeOptions }
                            selectedZones={ selectedZones }
                            selectedWards={ selectedWards }
                            selectedPlaceTypes={ selectedPlaceTypes }
                            onZoneChange={ onZoneChange }
                            onWardChange={ onWardChange }
                            onPlaceTypeChange={ onPlaceTypeChange }
                            wardPoints={ wardPoints }
                            isLoading={ isLoading }
                        />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={ <EnvironmentOutlined /> } key='2'>
                        <HomeTabPane
                            zoneDropdownOptions={ zoneDropdownOptions }
                            wardDropdownOptions={ wardDropdownOptions }
                            placeTypeOptions={ placeTypeOptions }
                            selectedZones={ selectedZones }
                            selectedWards={ selectedWards }
                            selectedPlaceTypes={ selectedPlaceTypes }
                            onZoneChange={ onZoneChange }
                            onWardChange={ onWardChange }
                            onPlaceTypeChange={ onPlaceTypeChange }
                            wardPoints={ wardPoints }
                            isLoading={ isLoading }
                        />
                    </Tabs.TabPane>
                </TabsMenu>

                {/* <Button
                    icon={ <LogoutOutlined /> }
                    onClick={ this._onLogout }
                    style={{ margin: '8px', marginTop: 'auto', marginLeft: 'auto' }}
                >
                    { 'Logout' }
                </Button> */}
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between'
}

// Prop Types
LeftPanelBody.propTypes = {
    zoneDropdownOptions: PropTypes.array,
    wardDropdownOptions: PropTypes.array,
    placeTypeOptions: PropTypes.array,
    selectedZones: PropTypes.array,
    selectedWards: PropTypes.array,
    selectedPlaceTypes: PropTypes.array,
    onZoneChange: PropTypes.func,
    onWardChange: PropTypes.func,
    onPlaceTypeChange: PropTypes.func,
    wardPoints: PropTypes.object,
    isLoading: PropTypes.bool
}

LeftPanelBody.defaultProps = {
    zoneDropdownOptions: [],
    wardDropdownOptions: [],
    placeTypeOptions: [],
    selectedZones: [],
    selectedWards: [],
    selectedPlaceTypes: [],
    onZoneChange: () => null,
    onWardChange: () => null,
    onPlaceTypeChange: () => null,
    wardPoints: null,
    isLoading: false
}

// Prop Types
LeftPanelBody.propTypes = {
    dispatch: PropTypes.func
}

LeftPanelBody.defaultProps = {
    dispatch: () => null
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LeftPanelBody)