import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Tabs } from 'antd'

// Import Actions & Methods
import { setSelectedTabKey } from '../../store/actions/navActions'

class TabsMenu extends React.PureComponent {
    componentDidMount() {
        const { dispatch } = this.props

        // Dispatch Initial Selected Tab Key
        dispatch( setSelectedTabKey('2') )
    }

    // On Tab Change
    _onChange = selectedTabKey => {
        const { dispatch } = this.props

        // Dispatch Selected Tab Key
        dispatch( setSelectedTabKey(selectedTabKey) )
    }

    render() {
        const { children, selectedTabKey } = this.props

        return (
            <Tabs
                type='card'
                defaultActiveKey='1'
                activeKey={ selectedTabKey }
                centered={ true }
                animated={{ tabPane: true }}
                size='small'
                tabBarGutter={ 0 }
                onChange={ this._onChange }
            >
                { children }
            </Tabs>
        )
    }
}

// Prop Types
TabsMenu.propTypes = {
    selectedTabKey: PropTypes.string,
    dispatch: PropTypes.func
}

TabsMenu.defaultProps = {
    selectedTabKey: '1',
    dispatch: () => null
}

const mapStateToProps = state => ({
    selectedTabKey: state.nav.selectedTabKey
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TabsMenu)