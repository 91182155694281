import React from 'react'

// Import Components
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'

class FullscreenControl extends React.PureComponent {
  state = {
    isHovered: false,
    isFullscreen: false
  }

  componentDidMount() {
    // Add fullscreenchange Event
    document.addEventListener('fullscreenchange', () => {
      const fullscreenElement = document.fullscreenElement
      this.setState({ isFullscreen: Boolean(fullscreenElement) })
    })

    // Add fullscreenerror Event
    document.addEventListener('fullscreenerror', e => {
      console.error(e)
      this.setState({ isFullscreen: false })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFullscreen } = this.state

    // If isFullscreen changes in state
    if(prevState.isFullscreen !== isFullscreen) {
      const fullscreenElement = document.fullscreenElement
      if(isFullscreen) {
        const mapContainer = document.getElementById('map-container')
        if(mapContainer && !fullscreenElement) {
          mapContainer.requestFullscreen()
        }

      } else {
        if(fullscreenElement) {
          document.exitFullscreen()
        }
      }
    }
  }

  // Toggle Is Hovered
  _toggleIsHovered = () => {
    const { isHovered } = this.state
    this.setState({ isHovered: !isHovered })
  }

  // Toggle Fullscreen
  _toggleFullscreen = () => {
    const { isFullscreen } = this.state
    this.setState({ isFullscreen: !isFullscreen })
  }

  render() {
    const { isHovered, isFullscreen } = this.state

    return (
      <div style={ containerStyles }>
        <div
          onMouseEnter={ this._toggleIsHovered }
          onMouseLeave={ this._toggleIsHovered }
          onClick={ this._toggleFullscreen }
          style={ iconContainerStyles }
        >
          { isFullscreen ?
            (
              <FullscreenExitOutlined
                style={{
                  fontSize: '20px',
                  color: isHovered ? '#f5f5f5' : '#88909d'
                }}
              />
            )
            :
            (
              <FullscreenOutlined
                style={{
                  fontSize: '20px',
                  color: isHovered ? '#f5f5f5' : '#88909d'
                }}
              />
            )
          }
        </div>
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start'
}

const iconContainerStyles = {
  width: '32px',
  height: '32px',
  padding: '6px',
  background: '#29323c',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'auto',
  cursor: 'pointer'
}

export default FullscreenControl