import * as ActionTypes from '../actions/actionTypes'

const initialState = {
  selectedZones: [ 'All' ]
}

const zoneReducer = (state=initialState, action) => {
  switch(action.type) {
    case ActionTypes.SET_SELECTED_ZONES:
      return {
        ...state,
        selectedZones: action.payload
      }

    default:
      return state
  }
}

export default zoneReducer