import { KeplerGlSchema } from 'kepler.gl/schemas'
import { receiveMapConfig, mapStyleChange } from 'kepler.gl/actions'

// Import Map Configs
import initial_custom_map_config from '../../configs/initial_custom_map_config.json'

// Load Initial Custom Map with Two Default Styles such as OSM Liberty and Barikoi Dark
export function loadInitialCustomMap() {
  return dispatch => {
      const initialCustomMapConfig = initial_custom_map_config
      const parsedInitialCustomMapConfig = KeplerGlSchema.parseSavedConfig(initialCustomMapConfig)
      dispatch( receiveMapConfig(parsedInitialCustomMapConfig) )
  }
}

// Set Current Map Style
export function setCurrentMapStyle(mapStyleType) {
  return (dispatch, getState) => {
    const { map } = getState().keplerGl
    if(map) {
      // Check if already selected
      const { styleType } = map.mapStyle
      if(styleType === mapStyleType) {
        return
      }

      dispatch( mapStyleChange(mapStyleType) )
    }
  }
}