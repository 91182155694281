import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Typography, Select } from 'antd'

class PlaceTypeSelectMenu extends React.PureComponent {
    render() {
        const { placeTypeOptions, selectedPlaceTypes, onPlaceTypeChange, disabled, locale } = this.props

        return (
            <div style={ containerStyles }>
                <Typography.Title
                    level={ 5 }
                    type='secondary'
                >
                    { locale === 'bn' ?
                        'জায়গার ধরন নির্বাচন করুন'
                        :
                        'Select Tax Types'
                    }
                </Typography.Title>

                <Select
                    mode='multiple'
                    allowClear={ true }
                    options={ placeTypeOptions }
                    placeholder={
                        locale === 'bn' ?
                        'জায়গার ধরন নির্বাচন করুন'
                        :
                        'Select Tax Types...'
                    }
                    showArrow={ true }
                    loading={ false }
                    value={ selectedPlaceTypes }
                    onChange={ onPlaceTypeChange }
                    disabled={ disabled }
                    style={{ width: '100%' }}
                />
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

// Prop Types
PlaceTypeSelectMenu.propTypes = {
    placeTypeOptions: PropTypes.array,
    selectedPlaceTypes: PropTypes.array,
    onPlaceTypeChange: PropTypes.func,
    disabled: PropTypes.bool,
    locale: PropTypes.string
}

PlaceTypeSelectMenu.defaultProps = {
    placeTypeOptions: [],
    selectedPlaceTypes: [],
    onPlaceTypeChange: () => null,
    disabled: false,
    locale: 'en'
}

const mapStateToProps = state => ({
    locale: state.locale.locale
})

export default connect(mapStateToProps, null)(PlaceTypeSelectMenu)