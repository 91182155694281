import { processGeojson, processRowObject } from 'kepler.gl/processors'
import { addDataToMap, layerConfigChange, layerVisConfigChange, layerVisualChannelConfigChange, addFilter, setFilter, interactionConfigChange, removeDataset, layerTextLabelChange } from 'kepler.gl/actions'
import { getCentersOfGeoJsonFeatures } from '../../utils/utils'
import { ZONE_AREAS } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Dispatch Zone Areas To Map
export function loadZoneAreas(zoneAreas) {
  return (dispatch, getState) => {
    // Remove Previous Dataset
    dispatch( removeDataset(ZONE_AREAS.DATA_ID) )
    dispatch( removeDataset(ZONE_AREAS.CENTER_DATA_ID) )

    if(zoneAreas) {
      // Build `zone-areas` dataset
      const datasetInfo = { id: ZONE_AREAS.DATA_ID, label: ZONE_AREAS.DATA_LABEL }
      const data = processGeojson(zoneAreas)
      const dataset = { info: datasetInfo, data }

      // Get Zone Area Centers GeoJson & Build `zone-area-centers` dataset
      const centers = getCentersOfGeoJsonFeatures(zoneAreas)
      const centerDatasetInfo = { id: ZONE_AREAS.CENTER_DATA_ID, label: ZONE_AREAS.CENTER_DATA_LABEL }
      const centerData = processRowObject(centers)
      const centerDataset = { info: centerDatasetInfo, data: centerData }

      // Options & Configs
      const options = { readOnly: true, centerMap: true }

      // Add Data to Map
      dispatch( addDataToMap({ datasets: [ dataset, centerDataset ], options }) )

      // Apply Configs
      const { map } = getState().keplerGl

      if(map) {
        // Layer Configs
        const { layers } = map.visState
        layers.forEach(l => {
          if(l.type === 'geojson' && l.config.dataId === datasetInfo.id) {
            // Apply Styles
            dispatch( layerVisConfigChange(l, {
              opacity: 0.01,
              strokeOpacity: 0.8,
              strokeColor: ZONE_AREAS.STROKE_COLOR,
              thickness: 1
            }) )

            // Color Range
            const colorRange = {
              category: 'Barikoi',
              colors: ZONE_AREAS.COLOR_PALETTE,
              name: 'Zones',
              type: 'quantile'
            }

            dispatch( layerVisConfigChange(l, { colorRange }) )

            // Set Color Field by to distinguish Zone Areas
            const _zoneAreasDataset = map.visState.datasets[ l.config.dataId ]
            const typeField = _zoneAreasDataset.fields.find(el => el.name === ZONE_AREAS.COLOR_KEY)

            // Dispatch Color Field By
            dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )

            // Add Filter
            dispatch( addFilter(l.config.dataId) )

            // Tooltip Configs
            const fieldsToShow = ZONE_AREAS.TOOLTIP_FIELDS
            const { tooltip } = map.visState.interactionConfig
            tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
            dispatch( interactionConfigChange(tooltip) )
          }

          if(l.type === 'point' && l.config.dataId === centerDatasetInfo.id) {
            dispatch( layerConfigChange(l, { color: [ 255, 255, 255, 0 ] }) )
            dispatch( layerVisConfigChange(l, { radius: 0 }) )

            // Set Outlets Text Label to `label`
            const centersDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
            const labelField = centersDataset?.fields.find(el => el.name === 'label')
            if(labelField) {
              dispatch( layerTextLabelChange(l, 0, 'field', labelField) )
              dispatch( layerTextLabelChange(l, 0, 'size', 22) )
              dispatch( layerTextLabelChange(l, 0, 'anchor', 'middle') )
              dispatch( layerTextLabelChange(l, 0, 'alignment', 'center') )

              // Set Text Label Color
              l.config.textLabel.forEach((label, li) => {
                dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
              })
            }

            // Add Filter
            dispatch( addFilter(l.config.dataId) )
          }
        })
      }
    }
  }
}

// Filter Zone Areas
export function filterZoneAreas(selectedZones) {
  return (dispatch, getState) => {
    let zoneNumbers = []

    // If selectedZones is empty
    if(!selectedZones || selectedZones.length === 0) {
      zoneNumbers = [ '' ]

    } else if(selectedZones.includes('All')) {
        // If `All` is selected
      zoneNumbers = []

    } else {
      // If Valid Zones Selected
      zoneNumbers = selectedZones
    }

    // Set Filter
    const { map } = getState().keplerGl
    if(map) {
      // Get Filter Index
      const { filters } = map.visState

      const filterIndex = filters.findIndex(f => f.dataId[0] === ZONE_AREAS.DATA_ID)
      const centerFilterIndex = filters.findIndex(f => f.dataId[0] === ZONE_AREAS.CENTER_DATA_ID)

      if(filterIndex >= 0) {
        // Filter Zones
        dispatch( setFilter(filterIndex, 'name', ZONE_AREAS.FILTER_KEY) )
        dispatch( setFilter(filterIndex, 'value', zoneNumbers) )

        // Filter Zone Centers
        dispatch( setFilter(centerFilterIndex, 'name', ZONE_AREAS.CENTER_FILTER_KEY) )
        dispatch( setFilter(centerFilterIndex, 'value', zoneNumbers) )
      }
    }
  }
}

// Set Zone Areas Layer Visibility
export function setZoneAreasLayerVisibility(isVisible) {
  return (dispatch, getState) => {
    const { map } = getState().keplerGl
    if(map) {
      const { layers } = map.visState
      layers.forEach(l => {
        if(l.type === 'geojson' && l.config.dataId === ZONE_AREAS.DATA_ID) {
          dispatch( layerConfigChange(l, { isVisible }) )
        }
      })
    }
  }
}

// Set Selected Zones
export function setSelectedZones(selectedZones) {
  return dispatch => {
    dispatch({ type: ActionTypes.SET_SELECTED_ZONES, payload: selectedZones })
  }
}