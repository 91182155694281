import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ZONE_AREAS, WARD_AREAS } from '../../App.config'

// Import Components
import { Space, Button } from 'antd'

// Import Actions & Methods
import { setSelectedZones } from '../../store/actions/zoneActions'
import { setSelectedWards, setWardPoints } from '../../store/actions/wardActions'

class CustomLayerHoverInfo extends React.PureComponent {
  // Render Key Value Pairs
  _renderKeyValuePairs = () => {
    const { fieldsToShow, fields, data } = this.props

    const domList = fieldsToShow.map(f => {
      const fieldIndex = fields.findIndex(fld => fld.name === f.name)

      if(!data?._dataContainer?._rows?.length || fieldIndex < 0) {
        return null
      }

      return (
        <tr key={ fieldIndex } style={ trStyles }>
          <td style={ tdKeyStyles }>{ f.name }</td>
          <td style={ tdValueStyles }>
            { data._dataContainer._rows[ data._rowIndex ][ fieldIndex ] }
          </td>
        </tr>
      )
    })

    return domList
  }

  // Load Wards Under Zone
  _loadWards = () => {
    const { fields, data, wardDataset, dispatch } = this.props

    if(!fields || !wardDataset || !wardDataset.dataContainer || !wardDataset.dataContainer._rows || !data || !data._dataContainer || !data._dataContainer._rows) {
      return
    }

    const selectedZone = data._dataContainer._rows[ data._rowIndex ][0].properties.zone_number

    const filteredWards = wardDataset.dataContainer._rows.filter(r =>
      r[0].properties.zone_number === selectedZone
    )
    .map(w => w[0].properties.ward_number)

    // Set Selected Zones
    dispatch( setSelectedZones([ selectedZone ]) )

    // Set Selected Wards
    dispatch( setSelectedWards(filteredWards) )

    // Clear Ward Points
    dispatch( setWardPoints(null) )
  }

  // Clear Wards
  _clearWards = () => {
    const { dispatch } = this.props

    // Set Selected Wards
    dispatch( setSelectedWards([]) )

    // Clear Ward Points
    dispatch( setWardPoints(null) )
  }

  // Load Holdings
  _loadHoldings = () => {
    const { fields, data, wardDataset, dispatch } = this.props

    if(!fields || !wardDataset || !wardDataset.dataContainer || !wardDataset.dataContainer._rows || !data || !data._dataContainer || !data._dataContainer._rows) {
      return
    }

    const selectedWard = data._dataContainer._rows[ data._rowIndex ][0].properties.ward_number

    // Set Selected Wards
    dispatch( setSelectedWards([]) )
    setTimeout(() => {
      dispatch( setSelectedWards([ selectedWard ]) )
    }, 10)
  }

  // Clear Holdings
  _clearHoldings = () => {
    const { dispatch } = this.props

    // Clear Ward Points
    dispatch( setWardPoints(null) )
  }
  
  render() {
    const { layer } = this.props

    return (
      <div style={ containerStyles }>
        <div style={ layerLabelContainerStyles }>
          <svg viewBox='0 0 64 64' width='12px' height='12px' style={{ fill: 'currentcolor' }}>
            <path d="M50.88,43.52a3.2,3.2,0,0,1,0,5.86L34.56,56.52a6.42,6.42,0,0,1-5.13,0L13.12,49.37a3.2,3.2,0,0,1,0-5.86l4.62-2a6,6,0,0,0,1.48,1l2.16.95-7,3.05,16.32,7.14a3.19,3.19,0,0,0,2.56,0L49.6,46.44l-7-3.05,2.16-.95a6,6,0,0,0,1.48-.95Zm0-14.39a3.2,3.2,0,0,1,0,5.86L34.56,42.13a6.42,6.42,0,0,1-5.13,0L13.12,35a3.2,3.2,0,0,1,0-5.86l4.62-2a6,6,0,0,0,1.48,1l2.16.95-7,3.05L30.72,39.2a3.19,3.19,0,0,0,2.56,0L49.6,32.06l-7-3.05,2.16-.95a6,6,0,0,0,1.48-.95ZM13.12,20.6a3.2,3.2,0,0,1,0-5.86L29.44,7.6a6.39,6.39,0,0,1,5.13,0l16.32,7.14a3.2,3.2,0,0,1,0,5.86L34.56,27.74a6.39,6.39,0,0,1-5.13,0Z" />
          </svg>
          <span style={ layerLabelStyles }>
            { layer && layer.config && layer.config.label ? layer.config.label : 'Layer' }
          </span>
        </div>

        <div style={ keyValueContainerStyles }>
          <table style={ tableStyles }>
            <tbody style={ tBodyStyles }>
              { this._renderKeyValuePairs() }
            </tbody>
          </table>
        </div>

        { layer && layer.config && layer.config.dataId === ZONE_AREAS.DATA_ID &&
          <div style={ actionContainerStyles }>
            <Space direction='horizontal' align='start' wrap={ true }>
              <Button
                type='ghost'
                size='small'
                onClick={ this._loadWards }
                style={{
                  borderColor: '#40a9ff',
                  color: '#40a9ff',
                  fontSize: '11px'
                }}
              >
                { 'Load Wards' }
              </Button>

              <Button
                type='ghost'
                size='small'
                onClick={ this._clearWards }
                style={{
                  borderColor: '#f5f5f5',
                  color: '#f5f5f5',
                  fontSize: '11px'
                }}
              >
                { 'Clear Wards' }
              </Button>
            </Space>
          </div>
        }

        { layer && layer.config && layer.config.dataId === WARD_AREAS.DATA_ID &&
          <div style={ actionContainerStyles }>
            <Space direction='horizontal' align='start' wrap={ true }>
              <Button
                type='ghost'
                size='small'
                onClick={ this._loadHoldings }
                style={{
                  borderColor: '#40a9ff',
                  color: '#40a9ff',
                  fontSize: '11px'
                }}
              >
                { 'Load Holdings' }
              </Button>

              <Button
                type='ghost'
                size='small'
                onClick={ this._clearHoldings }
                style={{
                  borderColor: '#f5f5f5',
                  color: '#f5f5f5',
                  fontSize: '11px'
                }}
              >
                { 'Clear Holdings' }
              </Button>
            </Space>
          </div>
        }
      </div>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start'
}

const layerLabelContainerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const layerLabelStyles = {
  boxSizing: 'border-box',
  margin: 0,
  marginLeft: '4px',
  marginBottom: '-4px',
  padding: 0,
  fontSize: '12px'
}

const keyValueContainerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  marginTop: '8px',
  padding: 0
}

const tableStyles = {
  display: 'table'
}

const tBodyStyles = {
  display: 'table-row-group'
}

const trStyles = {
  display: 'table-row'
}

const tdKeyStyles = {
  display: 'table-cell',
  paddingRight: '8px'
}

const tdValueStyles = {
  display: 'table-cell',
  paddingLeft: '8px',
  maxWidth: '320px',
  whiteSpace: 'wrap'
}

const actionContainerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  marginTop: '8px',
  padding: 0
}

// Prop Types
CustomLayerHoverInfo.propTypes = {
  wardDataset: PropTypes.object,
  dispatch: PropTypes.func
}

CustomLayerHoverInfo.defaultProps = {
  wardDataset: {},
  dispatch: () => null
}

const mapStateToProps = state => ({
  wardDataset: state.keplerGl.map ?
    state.keplerGl.map.visState.datasets[ WARD_AREAS.DATA_ID ]
    :
    {}
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(CustomLayerHoverInfo)